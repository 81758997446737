import '../../../styles/factory.css';
import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { TransactionButton } from '../../../functions/Trx'; 
import assetIcon from '../../../img/icons/Avatar.png';
import honeyIcon from '../../../img/icons/HoneyIcon.png';
import { useAppContext } from '../../../context/AppContext'; // Import the context hook

const Factory = () => {
    const {
        address,
        assetDetails,
        USDCBalance,
        assetFactory_nm,
        assetFactory_Address,
        web3_nm,
        outputNumber,
        updateAssetBalance,
        loadUSDBalance,
        ERC20_ABI,
        stableCoinName,
        assetFactory_ABI,
        USDDecimals,
        setMaxAmount,
        USDC_Address,
        USDC_nm,
    } = useAppContext();

    const [mintAmount, setMintAmount] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState('Select an asset');
    const [errorButtonVisible, setErrorButtonVisible] = useState(false);
    const [errorButtonMessage, setErrorButtonMessage] = useState('');
    const [chooseAssetModalOpen, setChooseAssetModalOpen] = useState(false);
    const [assets, setAssets] = useState([]);
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [tokenAmount, setTokenAmount] = useState();
    const [USDCAllowance, setUSDCAllowance] = useState(0);
    
    console.log(assetFactory_ABI)
    useEffect(() => {
        if (parseInt(USDCAllowance) > 0) {
            setErrorButtonVisible(false);
        }
    }, [USDCAllowance]);

    const openModal = async () => {
        if (parseInt(USDCAllowance) > 0) {
            setErrorButtonVisible(false);
        } else {
            setErrorButtonVisible(true);
        }

        const investmentAmount = document.getElementById('amountToStake').value;
        const assetDetails = await assetFactory_nm.methods.getAsset(selectedAsset).call();
        const upperLimit = Number(assetDetails.upperLimit) / 1000;
        const tokenAmount = investmentAmount / upperLimit;
        setModalOpen(true);
        setTokenAmount(tokenAmount);
    };

    const closeModal = () => setModalOpen(false);

    const onSuccessMint = async () => {
        await updateAssetBalance(selectedAsset);
        await loadUSDBalance();
        document.getElementById('amountToStake').value = 0;
        closeModal();
    };

    const onSuccessApprove = async() =>{  
        changeAmount();
    };

    const changeAmount = async () => {
        let input = document.getElementById('amountToStake').value
        if (selectedAsset === 'Select an asset'){
            document.getElementById('amountToStake').value = ''
            return
        }
        if (document.getElementById('amountToStake').value === ''){
            console.log("Empty field")
            setMintAmount(0)
            return
        }
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(document.getElementById('amountToStake').value);
        if (isPositiveNumber === false){
            console.log("debug")
            if(Number.isNaN(mintAmount) || typeof(mintAmount) == 'undefined'){
                document.getElementById('tokenAmountA').value = ''
            }
            else{
                console.log(mintAmount)
                document.getElementById('amountToStake').value = mintAmount
            }
            return
        }

        if (parseFloat(input) === 0){
            console.log("input 0")
            return
        }
        let allowance = await USDC_nm.methods.allowance(address, assetFactory_Address).call()
        setUSDCAllowance(parseInt(allowance))
        const amount = document.getElementById('amountToStake').value;
        console.log(amount)
        setMintAmount(amount > 0 ? amount : 0);

        if (amount > parseInt(USDCBalance)) {
            setErrorButtonVisible(true);
            setErrorButtonMessage("Balance too low");
        } else if (selectedAsset === "Select an asset") {
            setErrorButtonVisible(true);
            setErrorButtonMessage("Select an asset");
        } else {
            setErrorButtonVisible(false);
        }
    };

    const openChooseAssetModal = () => {
        const assetsList = Object.keys(assetDetails)
            .filter(key => assetDetails[key].frozen === false && assetDetails[key].expiryTime > Date.now() / 1000)
            .map(key => [
                key,
                assetDetails[key]['tokenBalance1'],
                assetDetails[key]['tokenBalance2'],
                assetDetails[key]['name'],
                assetDetails[key]['upperLimit']
            ]);

        setAssets(assetsList);
        setFilteredAssets(assetsList);
        setChooseAssetModalOpen(true);
    };

    const closeChooseAssetModal = () => setChooseAssetModalOpen(false);

    const filterAssets = () => {
        const searchTerm = document.getElementById('search').value.toLowerCase();
        const filtered = assets.filter(asset => asset[3].toLowerCase().includes(searchTerm) || asset[0].toLowerCase().includes(searchTerm));
        setFilteredAssets(filtered);
    };

    const listMintableAssets = () => {
        return filteredAssets.map((element, index) => (
            <div key={index} className="assetSelectList" role="button" onClick={() => selectAsset(element[0])}>
                <div className="assetSelectListName">
                    <div><b>{element[0]}</b></div>
                    <div><b>{element[3]}</b></div>
                </div>
                <div className="assetSelectListBalance">
                    <div>Balance: {outputNumber(element[1], 4)} LONG / {outputNumber(element[2], 4)} SHORT</div>
                    <div>UL: {outputNumber(Number(element[4]) / 1000, 0)} {stableCoinName}</div>
                </div>
            </div>
        ));
    };

    const selectAsset = asset => {
        setSelectedAsset(asset);
        closeChooseAssetModal();
    };

    return (
        <div className="mainContainer">
            <Modal show={chooseAssetModalOpen} onHide={closeChooseAssetModal}>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title>Select TWIN Asset pair to mint</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <div className="row p-3 pr-3 my-auto">
                        <input type="text" className="searchField col w-100" id="search" placeholder="Search" onChange={filterAssets}></input>
                    </div>
                    <div className="list-group list-assets">{listMintableAssets()}</div>
                </Modal.Body>
            </Modal>

            <Modal show={modalOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>You will receive:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="factoryModalHeader">{selectedAsset}</div>
                    <div className='factoryModalBody'>
                        {outputNumber(parseFloat(tokenAmount), 4)} LONG & SHORT Tokens.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <TransactionButton
                        abi={assetFactory_ABI}
                        contractAddress={assetFactory_Address}
                        functionName="mintAssets"
                        args={[selectedAsset, web3_nm.utils.toBigInt(mintAmount*(10 ** USDDecimals)) ]}
                        text="Mint"
                        onSuccess={onSuccessMint}
                        gasLimit={600000}
                        className="w-100"
                    />
                </Modal.Footer>
            </Modal>

            <div className="middleBoxSmall">
                <div className='row m-0'>
                    <div className="mainBox">
                        <div className='factoryHeadline'>Select a TWIN Asset pair to mint</div>
                        <div className="subBox">
                            <div className="selectAssetButton factorySelectAssetButton" onClick={openChooseAssetModal}>
                                <div className='factorySelectButtonText'>
                                    <div className='assetBoxIcon'>
                                        <img src={assetIcon} alt="asset icon" width="24px" />
                                    </div>
                                    <div>{selectedAsset}</div>
                                </div>
                                <div className='factoryIconRight'><KeyboardArrowDownOutlinedIcon /></div>
                            </div>
                        </div>
                        <div className='factoryHeadline pt-4'>{stableCoinName} amount to deposit</div>
                        <div className="subBox">
                            <div className="subBoxLine">
                                <input placeholder="0.00" className="inputCustom" type='text' id='amountToStake' onChange={changeAmount}></input>
                                <div className='stablecoinAssetBox'>
                                    <div className='assetBoxIcon'>
                                        <img src={honeyIcon} alt="honey icon" width="24px" />
                                    </div>
                                    {stableCoinName}
                                </div>
                            </div>
                            <div onClick={setMaxAmount} role="button" className="">
                                Balance: <span className="tradeBalance">{typeof (USDCBalance) !== 'undefined' ? `${outputNumber(parseInt(USDCBalance), 0)} ${stableCoinName} (Max)` : ''}</span>
                            </div>
                        </div>

                        <div className="">
                            {mintAmount > USDCAllowance / (10 ** USDDecimals) ? (
                                <TransactionButton
                                    contractAddress={USDC_Address}
                                    abi={ERC20_ABI}
                                    functionName='approve'
                                    args={[assetFactory_Address, web3_nm.utils.toBigInt(2 ** 255)]}
                                    text="Approve"
                                    onSuccess={onSuccessApprove}
                                />
                            ) : null}

                            {errorButtonVisible ? (
                                <div className="w-100 issuaaButtonDeactivated">{errorButtonMessage}</div>
                            ) : null}

                            {mintAmount < USDCAllowance / (10 ** USDDecimals) && !errorButtonVisible && mintAmount > 0 ? (
                                <div role='button' className="w-100 issuaaButton" onClick={openModal}>
                                    Submit
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Factory;
