import '../../../styles/mainpage.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext'; // Import useAppContext

import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

const Mainpage = () => {
    // Access the context values
    const {
        assets,
        pools,
        outputNumber,
        ISSPrice,
        totalLockedValue,
        ISSMarketCapCurrent,
        farmActive
    } = useAppContext();

    const [poolOutput, setPoolOutput] = useState('');

    useEffect(() => {
        if (typeof pools !== 'undefined') {
            setPoolOutput(pools.map((pool, index) => (
                <tr key={index}>
                    <td><div className="firstRow">{pool[0]}</div></td>
                    <td className="text-left">{pool[3]}</td>
                    <td className="text-right">{outputNumber(pool[10], 2)}</td>
                    <td className="text-right">{outputNumber(parseFloat(pool[9]) / 1000, 0)}</td>
                    <td className="text-right">{outputNumber(pool[2],0)}</td>
                    <td className="text-right">{outputNumber(pool[2] * parseFloat(pool[4]) / parseFloat(pool[5]), 0)}</td>
                    <td>
                        <div className="d-flex flex-row">
                            <Link to={`/trade/buy/${pool[1]}`}>
                                <div className="buyButton">Buy</div>
                            </Link>
                            <Link to={`/trade/sell/${pool[1]}`}>
                                <div className="sellButton">Sell</div>
                            </Link>
                        </div>
                    </td>
                    
                </tr>
            )));
        }
    }, [pools, outputNumber, farmActive]);

    return (
        <div className="mainContainer">
            <div className="middleBoxFull">
                <div className="contentContainer">
                    <div className="infoRow">
                        <div className="infoBox">
                            <div className='infoBoxIcon'><AttachMoneyOutlinedIcon fontSize='inherit' /></div>
                            <div className="infoBoxItem">$ {outputNumber(parseFloat(ISSPrice), 3) > 0 ?
                                outputNumber(parseFloat(ISSPrice), 3)
                                :
                                <span>...</span>
                            }
                            </div>
                            <div className='infoBoxItem2'>TWIN Price</div>
                        </div>

                        <div className="infoBox">
                            <div className='infoBoxIcon'><WorkOutlineOutlinedIcon fontSize='inherit' /></div>
                            <div className="infoBoxItem">{typeof assets !== 'undefined' ? assets.length * 2 + 1 : <span>...</span>}</div>
                            <div className="infoBoxItem2">Assets</div>
                        </div>
                        <div className="infoBox">
                            <div className='infoBoxIcon'><AttachMoneyOutlinedIcon fontSize='inherit' /></div>
                            <div className="infoBoxItem">$ {typeof totalLockedValue !== 'undefined' ? outputNumber(totalLockedValue / 1e6, 2) : <span>...</span>}m</div>
                            <div className="infoBoxItem2">Value locked</div>
                        </div>
                        <div className="infoBox">
                            <div className='infoBoxIcon'><PieChartRoundedIcon fontSize='inherit' /></div>
                            <div className="infoBoxItem">$ {typeof assets !== 'undefined' ? outputNumber(ISSMarketCapCurrent / 1e6, 2) : <span>...</span>}m</div>
                            <div className="infoBoxItem2">TWIN Market Cap</div>
                        </div>
                    </div>

                    <div className="mainpageDashboard">
                        <div className="col p-3 pl-4 pb-0">
                            <div className="mainpageHeadline">TWIN Assets and pools</div>
                            <br />
                            <div className="">
                                <table className="mainTable w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col">Symbol</th>
                                            <th className="text-left" scope="col">Asset</th>
                                            <th className="text-right" scope="col">Price</th>
                                            <th className="text-right" scope="col">Upper Limit</th>
                                            <th className="text-right" scope="col">Pool liq.(USD)</th>
                                            <th className="text-right" scope="col">Your stake (USD)</th>
                                            <th></th>
                                            {farmActive ? <th className="text-right" scope="col">APR</th> : ''}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {poolOutput}
                                    </tbody>
                                </table>
                                <div className="buffer"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mainpage;
