import '../../../styles/tradeHistory.css';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/AppContext';
import axios from 'axios';

const TradeHistory = ({ symbol }) => {
    // Access context values
    const { address } = useAppContext();

    // State to store trade data
    const [trades, setTrades] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch trade data from the API
    useEffect(() => {
        const fetchTradeHistory = async () => {
            let apiAddress = ''
            if (symbol === 'Select Asset'){
                apiAddress = `https://api.twinfinance.io:9443/trades?addressTrader=${address}`
            }
            else {
                apiAddress = `https://api.twinfinance.io:9443/trades?addressTrader=${address}${symbol ? `&symbol=${symbol}` : ''}`
            }
            try {
                const response = await axios.get(apiAddress);
                setTrades(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching trade history:", error);
                setLoading(false);
            }
        };

        if (address) {
            fetchTradeHistory();
        }
    }, [address, symbol]);

    return (
        <div className="mainContainer mt-3">
            <div className="middleBoxSmall">
                <div className="mainBox">
                    <div className="p-0 bold">
                        Trade History
                    </div>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <div>
                            {trades.length > 0 ? (
                                <table className="tradeHistoryTable">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Trx Hash</th>
                                            <th>Type</th>
                                            <th>Symbol</th>
                                            <th>Amount</th>
                                            <th>Volume Traded</th>
                                            <th>Price (USD)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trades.map((trade, index) => (
                                            <tr key={index}>
                                                <td>{new Date(trade.date).toLocaleString()}</td>
                                                <td>
                                                    <a 
                                                        href={`https://bartio.beratrail.io/tx/${trade.trxHash}`} 
                                                        target="_blank" 
                                                        rel="noopener noreferrer">
                                                        {trade.trxHash.slice(0, 3)}..{trade.trxHash.slice(-3)}
                                                    </a>
                                                </td>
                                                <td>{trade.trxType === 'trade - buy' ? 'Buy' : 'Sell'}</td>
                                                <td>
                                                    {trade.trxType === 'trade - buy' ? trade.symbolBuy : trade.symbolSell}
                                                </td>
                                                <td>
                                                    {trade.trxType === 'trade - buy' ? parseFloat(trade.amountBuy).toFixed(4) : parseFloat(trade.amountSell).toFixed(4)}
                                                </td><td>
                                                    {trade.trxType === 'trade - buy' ? parseFloat(trade.amountBuy*trade.priceUSDBuy).toFixed(1) : parseFloat(trade.amountSell*trade.priceUSDSell).toFixed(1)}
                                                </td>
                                                <td>
                                                    {trade.trxType === 'trade - buy' ? parseFloat(trade.priceUSDBuy).toFixed(2) : parseFloat(trade.priceUSDSell).toFixed(2)}  
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div>No trades found for this address.</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TradeHistory;
