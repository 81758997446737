import React, { useState, useEffect, useCallback } from 'react';
import { Button } from "react-bootstrap";
import { usePrepareContractWrite, useContractWrite } from 'wagmi';
import MessageBox from '../components/Common/MessageBox';
import { ethers } from 'ethers';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

export function TransactionButton({ abi, contractAddress, functionName, args, text, onSuccess, value = 0, gasLimit = null }) {
  console.log(contractAddress);
  console.log(functionName);
  console.log(args);
  console.log(gasLimit)
  //console.log(abi)
  
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageBoxHeader,setMessageBoxHeader] = useState('');
  const [messageBoxFooter,setMessageBoxFooter] = useState('');
  const [messageBoxContent, setMessageBoxContent] = useState('');
  const [isPending, setIsPending] = useState(false);

  // Build the config with gas if gasLimit is provided, otherwise omit it
  const configOptions = {
    address: contractAddress,
    abi,
    functionName,
    args,
    value
  };

  if (gasLimit) {
    configOptions.gas = ethers.BigNumber.from(gasLimit);
  }

  const { config } = usePrepareContractWrite(configOptions);
  console.log(config);
  const { data, error: writeError, isLoading, write } = useContractWrite(config);
  console.log(write)

  const showMessage = useCallback((message, header, footer, timeout = 500) => {
    setMessageBoxContent(message);
    setMessageBoxHeader(header)
    setMessageBoxFooter(footer)
    setShowMessageBox(true);
    setTimeout(() => setShowMessageBox(false), timeout);
  }, []);

  const pollTransactionStatus = useCallback(async (transactionHash) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let attempts = 0;
    const maxAttempts = 10;
    let interval = 2000; // Start with 2 seconds
    const maxInterval = 10000; // Maximum interval of 10 seconds

    const checkStatus = async () => {
      attempts++;
      try {
        const receipt = await provider.getTransactionReceipt(transactionHash);
        if (receipt && receipt.confirmations > 0) {
          setIsPending(false);
          showMessage('Transaction confirmed.');
          if (onSuccess) onSuccess();
          return;
        } else if (attempts < maxAttempts) {
          setTimeout(checkStatus, interval);
          interval = Math.min(interval * 1.4, maxInterval); // Exponential backoff with max cap
        } else {
          setIsPending(false);
          throw new Error('Transaction confirmation timeout.');
        }
      } catch (error) {
        setIsPending(false);
        showMessage(`Error: ${error.message}`);
      }
    };

    checkStatus();
  }, [showMessage, onSuccess]);

  useEffect(() => {
    if (data?.hash) {
      setIsPending(true);
      showMessage(data.hash);
      pollTransactionStatus(data.hash);
    }
  }, [data, pollTransactionStatus, showMessage]);

  useEffect(() => {
    if (writeError) {
      setIsPending(false);
      showMessage(`Error: ${writeError.message}`);
    }
  }, [writeError, showMessage]);

  const handleClick = useCallback(() => {
    if (write) {
      write();
    }
  }, [write]);

  return (
    <>
      {isPending ? 
      <div className="w-100 transactionButtonDisabled">
        <div className="spinner-border spinner-border-sm spinner-border-slow" role="status">
        </div>Waiting for confirmation
      </div>:
      <div role='button' className="w-100 transactionButton" onClick={handleClick} disabled={isLoading || isPending}>
        {isLoading ? 'Processing...' : text}
      </div> 
      }
      
      
      {showMessageBox && (
        <MessageBox 
          content={messageBoxContent}
          header="Transaction Hash" 
          footer={
            <a href={`https://bartio.beratrail.io/tx/${messageBoxContent}`} target="_blank" rel="noopener noreferrer">
              Blockchain Explorer <OpenInNewOutlinedIcon fontSize='inherit'/>
            </a>
          }
        />
      )}

    </>
  );
}
