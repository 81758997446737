import '../../../styles/burnAssets.css';
import React, { Component,useEffect,useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {TransactionButton} from '../../../functions/Trx'; 
import assetIcon from '../../../img/icons/Avatar.png';

import { useAppContext } from '../../../context/AppContext'; // Import the context hook

const MobileBurnAssets = () => {
    const {
        address,
        assetDetails,
        USDCBalance,
        assetFactory_nm,
        assetFactory_Address,
        web3_nm,
        outputNumber,
        updateAssetBalance,
        loadUSDBalance,
        ERC20_ABI,
        stableCoinName,
        assetFactory_ABI,
        USDDecimals,
        USDC_Address,
        USDC_nm,
        maxBurnableAmount,
        burnableExpiredAssets,
        burnableAssets,
        sleep,
        roundDown,
        assetFactory_nm_ABI

    } = useAppContext();

    const [allowance, setAllowance] = useState(0);
    const [allowance2, setAllowance2] = useState(0);
    const [allowanceButtonVisible1, setAllowanceButtonVisible1] = useState(false);
    const [allowanceButtonVisible2, setAllowanceButtonVisible2] = useState(false);
    const [amountToBurn, setAmountToBurn] = useState(false);
    const [amountToBurnLong, setAmountToBurnLong] = useState(0);
    const [amountToBurnShort, setAmountToBurnShort] = useState(0);
    const [amountToGet, setAmountToGet] = useState(0);
    const [amountToGetExpired, setAmountToGetExpired] = useState(0);
    const [approvalButtonVisible1, setApprovalButtonVisible1] = useState(false);
    const [approvalButtonVisible2, setApprovalButtonVisible2] = useState(false);
    const [assetBurnOptions, setAssetBurnOptions] = useState([]);
    const [assetBurnOptionsExpired, setAssetBurnOptionsExpired] = useState([]);
    const [assets, setAssets] = useState(['wait']);
    const [burnAmount1, setBurnAmount1] = useState(0);
    const [burnAmount2, setBurnAmount2] = useState(0);
    const [burnableBalanceVisible, setBurnableBalanceVisible] = useState(false);
    const [burnableBalanceToken1Visible, setBurnableBalanceToken1Visible] = useState(false);
    const [burnableBalanceToken2Visible, setBurnableBalanceToken2Visible] = useState(false);
    const [burnableBalanceToken1, setBurnableBalanceToken1] = useState(0);
    const [burnableBalanceToken2, setBurnableBalanceToken2] = useState(0);
    const [burnAmount, setBurnAmount] = useState(0);
    const [burnAmountLong, setBurnAmountLong] = useState(0);
    const [burnAmountShort, setBurnAmountShort] = useState(0);
    const [chooseAssetModalOpen, setChooseAssetModalOpen] = useState(false);
    const [chooseExpiredAssetModalOpen, setChooseExpiredAssetModalOpen] = useState(false);
    const [expiredTokenToBurn, setExpiredTokenToBurn] = useState('');
    const [filteredAssetBurnOptions, setFilteredAssetBurnOptions] = useState([]);
    const [filteredAssetBurnOptionsExpired, setFilteredAssetBurnOptionsExpired] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalBurnExpiredAssetsOpen, setModalBurnExpiredAssetsOpen] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState('Select Asset');
    const [selectedAssetExpired, setSelectedAssetExpired] = useState('Select Asset');
    const [selectedAssetBurnableBalance, setSelectedAssetBurnableBalance] = useState(0);
    const [style1, setStyle1] = useState('selectButtonSelected');
    const [style2, setStyle2] = useState('selectButtonUnselected');
    const [submitButtonDeactivated, setSubmitButtonDeactivated] = useState(false);
    const [submitButtonDeactivatedMessage, setSubmitButtonDeactivatedMessage] = useState('');
    const [submitButtonVisible, setSubmitButtonVisible] = useState(false);
    const [token1Address, setToken1Address] = useState('');
    const [token2Address, setToken2Address] = useState('');
    const [tokenToBurn, setTokenToBurn] = useState('');
    const [view, setView] = useState('live');

    const selectLiveView = async() =>{
        setView('live') 
        setStyle1("selectButtonSelected");
        setStyle2("selectButtonUnselected");
    }
    const selectExpiredView = async() =>{
        setView('expired') 
        setStyle1("selectButtonUnselected");
        setStyle2("selectButtonSelected");
    }

    const openModal = async () => {
        let _amountToBurn = document.getElementById('amountToBurn').value
        let AssetDetails  = await assetFactory_nm.methods.getAsset(selectedAsset).call()
        let upperLimit = parseFloat(AssetDetails.upperLimit)/1000
        console.log(upperLimit)
        let _amountToGet = upperLimit * _amountToBurn
        setAmountToBurn(_amountToBurn)
        setAmountToGet(_amountToGet)
        setModalOpen(true)
    };
    
    const closeModal = () => setModalOpen(false);

    const openModalBurnExpiredAssets = async () => {
        setTokenToBurn(selectedAssetExpired)
        let _amountToBurnLong = parseFloat(document.getElementById('amountToBurnToken1').value)
        let _amountToBurnShort = parseFloat(document.getElementById('amountToBurnToken2').value)
        let AssetDetails  = await assetFactory_nm.methods.getAsset(selectedAssetExpired).call()
        //console.log(AssetDetails)
        let upperLimit = parseInt(AssetDetails.upperLimit)/1000
        let expiryValue = parseInt(AssetDetails.endOfLifeValue)/1000
        //console.log(expiryValue)
        let _amountToGetExpired = expiryValue * _amountToBurnLong + (upperLimit - expiryValue)*_amountToBurnShort
        setAmountToBurnLong(_amountToBurnLong)
        setAmountToBurnShort(_amountToBurnShort)
        setAmountToGetExpired(_amountToGetExpired)
        setModalBurnExpiredAssetsOpen(true)
    };

    const closeModalBurnExpiredAssets = () => setModalBurnExpiredAssetsOpen(false);
    
    const onSuccessApprove = async(tokenAddress,approvalAddress) =>{
        if (view === 'live'){
            checkInputLive();
        }
        else {
            checkInputExpired();
        }
    }

    const setMaxAmount = async() =>{
        let amount = parseInt(selectedAssetBurnableBalance) / 1e18;
        //console.log(amount);
        amount = roundDown(amount,14);
        document.getElementById('amountToBurn').value = amount;
        //console.log(amount);
        checkInputLive();
        return
    }

    const setMaxAmountToken1 = async() =>{
        let amount = parseInt(burnableBalanceToken1) / 1e18;
        amount = roundDown(amount,14);
        //console.log(amount)
        document.getElementById('amountToBurnToken1').value = amount
        return
    }

    const setMaxAmountToken2 = async() =>{
        let amount = parseInt(burnableBalanceToken2) / 1e18;
        amount = roundDown(amount,14);
        document.getElementById('amountToBurnToken2').value = amount
        return
    }

    const onSuccessBurn = async() => {
        console.log(selectedAsset)
        setModalOpen(false)
        document.getElementById('amountToBurn').value = 0
        setSelectedAsset('Select Asset')
        setSelectedAssetBurnableBalance(0)
        await loadUSDBalance();
        console.log(tokenToBurn)
        await updateAssetBalance(tokenToBurn);
    }      
    
    const openChooseAssetModal=async()=>{
        let _assets = [];
        console.log(assetDetails)
        for (let key in assetDetails) {
            console.log(assetDetails[key])
            if (assetDetails[key]['frozen'] === false) {
                let burnableBalance = Math.min(assetDetails[key]['tokenBalance1'],assetDetails[key]['tokenBalance2'])
                if (assetDetails[key].expiryTime > Date.now()/1000){
                    _assets.push([key,burnableBalance,assetDetails[key]['name'],assetDetails[key]['upperLimit']])
                }
            }
            else{
                let burnableBalance = assetDetails[key]['tokenBalance1']
                _assets.push([key,burnableBalance,assetDetails[key]['name'],assetDetails[key]['upperLimit']])
            }
        }
        setChooseAssetModalOpen(true)
        setAssetBurnOptions(_assets)
        console.log(_assets)
        //filterAssets();
    };


    useEffect(() => {
        filterAssets()
    }, [assetBurnOptions]);

    const closeChooseAssetModal=()=>{
        setChooseAssetModalOpen(false)
    }  

    const openChooseExpiredAssetModal=async()=>{
        let _assets = [];
        for (let key in assetDetails) {
            let burnableBalance1 = assetDetails[key]['tokenBalance1']
            let burnableBalance2 = assetDetails[key]['tokenBalance2']
            if (assetDetails[key].expiryTime < Date.now()/1000){
                _assets.push([key,burnableBalance1,burnableBalance2,assetDetails[key]['name'],assetDetails[key]['upperLimit']])
            }
        }
        setAssetBurnOptionsExpired(_assets)
        setChooseExpiredAssetModalOpen(true)
        filterAssetsExpired()
    };
    useEffect(() => {
        filterAssetsExpired()
    }, [assetBurnOptionsExpired]);

    const closeChooseExpiredAssetModal=()=>{
        setChooseExpiredAssetModalOpen(false)
    }  

    const checkInputLive = async() =>{
        if(token1Address === ''){
            document.getElementById('amountToBurn').value = ''
            return
        }
        let _amountRaw = document.getElementById('amountToBurn').value*Number(1e18);
        console.log(_amountRaw)
        if (isNaN(_amountRaw)){
            document.getElementById('amountToBurn').value = burnAmount
            return
        }
        console.log(_amountRaw)
        var _amount = web3_nm.utils.toBigInt(_amountRaw)
        let tokenContract = new web3_nm.eth.Contract(ERC20_ABI,token1Address)
        let tokenContract2 = new web3_nm.eth.Contract(ERC20_ABI,token2Address)
        let _allowance = await tokenContract.methods.allowance(address, assetFactory_nm._address).call()
        let _allowance2 = await tokenContract2.methods.allowance(address, assetFactory_nm._address).call()
        setAllowance(parseInt(_allowance))
        setAllowance2(parseInt(_allowance2))
        setBurnAmount(parseInt(_amount)/1e18)
        
        if (_amountRaw > selectedAssetBurnableBalance) {
            setSubmitButtonDeactivated(true)
            setSubmitButtonDeactivatedMessage('Balance too low')
        }
        else if (allowanceButtonVisible1 || approvalButtonVisible2){
            setSubmitButtonDeactivated(true)
            setSubmitButtonDeactivatedMessage('Waiting for approval')            
        }
        else if (_amountRaw === 0){
            setSubmitButtonDeactivated(true)
            setSubmitButtonDeactivatedMessage('Select an amount greater than 0')            
        }
        else {
            setSubmitButtonDeactivated(false)
            setSubmitButtonVisible(true)
        }
    }

    const checkInputExpired = async() =>{
        let amt1 = document.getElementById('amountToBurnToken1').value*1e18;
        let amt2 = document.getElementById('amountToBurnToken2').value*1e18;

        var _amount1 = web3_nm.utils.toBigInt(amt1)
        var _amount2 = web3_nm.utils.toBigInt(amt2)
        let tokenContract = new web3_nm.eth.Contract(ERC20_ABI,token1Address)
        let tokenContract2 = new web3_nm.eth.Contract(ERC20_ABI,token2Address)
        let _allowance = await tokenContract.methods.allowance(address, assetFactory_nm._address).call()
        let _allowance2 = await tokenContract2.methods.allowance(address, assetFactory_nm._address).call()
        setAllowance(parseInt(_allowance))
        setAllowance2(parseInt(_allowance2))
        setBurnAmount1(parseInt(_amount1))
        setBurnAmount2(parseInt(_amount2))


        if (amt1 > burnableBalanceToken1 || amt2 > burnableBalanceToken2) {
            setSubmitButtonVisible(false)
            setSubmitButtonDeactivated(true)
            setSubmitButtonDeactivatedMessage('Balance too low')
        }
        else if (allowanceButtonVisible1 || allowanceButtonVisible2){
            setSubmitButtonVisible(false)
            setSubmitButtonDeactivated(true)
            setSubmitButtonDeactivatedMessage('Waiting for approval')
            
        }

        else {
            setSubmitButtonVisible(true)
            setSubmitButtonDeactivated(false)
        }
    }

      

    const listBurnableAssets = () => {
        let burnableAssets = filteredAssetBurnOptions.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>selectAssetLive(element[0])}>
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[2]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Burnable balance: {outputNumber(element[1],4)}</div>
                        <div className="col text-right">UL: {outputNumber(Number(element[3])/1000,0)}</div>
                    </div>
                </div>
        );
        return(burnableAssets)
    }


    const filterAssets = () => {
        let _filteredAssets =[];
        let searchTerm ='_';
        try{searchTerm = document.getElementById('search').value.toLowerCase()} catch {searchTerm ='_'}

        for (let i = 0; i < assetBurnOptions.length; ++i){
            console.log(assetBurnOptions[i])
            if ((assetBurnOptions[i][2].toLowerCase().includes(searchTerm) || assetBurnOptions[i][0].toLowerCase().includes(searchTerm))&&assetBurnOptions[i][1]>0.0000001){
                _filteredAssets.push(assetBurnOptions[i])
            }
            
        }
        setFilteredAssetBurnOptions(_filteredAssets)
    }

    const filterAssetsExpired = () => {
        let _filteredAssets =[];
        let searchTerm ='';
        try{searchTerm = document.getElementById('search').value.toLowerCase()}catch {searchTerm=''}
        for (let i = 0; i < assetBurnOptionsExpired.length; ++i) {
            if ((assetBurnOptionsExpired[i][3].toLowerCase().includes(searchTerm) || assetBurnOptionsExpired[i][0].toLowerCase().includes(searchTerm))&&assetBurnOptionsExpired[i][1]>0.0000001){
                _filteredAssets.push(assetBurnOptionsExpired[i])
            }
        }
        setFilteredAssetBurnOptionsExpired(_filteredAssets)
    }

    const selectAssetLive = async(asset) =>{
        //console.log(asset)
        //console.log(assetDetails)
        document.getElementById('amountToBurn').value = 0
        let _token1Address = assetDetails[asset][0]
        let _token2Address = assetDetails[asset][1]
        //console.log(_token1Address)
        //console.log(_token2Address)
        let tokenContract1 = new web3_nm.eth.Contract(ERC20_ABI,_token1Address)
        let balanceToken1 = await tokenContract1.methods.balanceOf(address).call()
        //console.log(balanceToken1)
        let tokenContract2 = new web3_nm.eth.Contract(ERC20_ABI,_token2Address)
        let balanceToken2 = await tokenContract2.methods.balanceOf(address).call()
        console.log(balanceToken2)
        if ((parseInt(balanceToken1) <= parseInt(balanceToken2)) || assetDetails[asset]['frozen'] === true){
            //console.log(balanceToken1)
            //console.log(balanceToken2)
            setSelectedAssetBurnableBalance(balanceToken1)
        }
        else {
            setSelectedAssetBurnableBalance(balanceToken2)
            console.log(assetDetails[asset]['frozen'])
        }
        setBurnableBalanceVisible(true)
        //console.log("DEBUG")
        let _allowance = await tokenContract1.methods.allowance(address, assetFactory_nm._address).call()
        setAllowance(_allowance)
        let _allowance2 = await tokenContract2.methods.allowance(address, assetFactory_nm._address).call()
        //console.log(_allowance2)
        setSelectedAsset(asset)
        setTokenToBurn(asset)
        setToken1Address(_token1Address)
        setToken2Address(_token2Address)
        
        setAllowance2(_allowance2)
        
        closeChooseAssetModal();
    };

    useEffect(() => {
        checkInputLive()
    }, [allowance2]);

    const listBurnableExpiredAssets = () => {
        console.log(filteredAssetBurnOptionsExpired)
        let burnableAssets = filteredAssetBurnOptionsExpired.map((element,index) =>
            <div key={index} className="assetSelectList" role="button" onClick={()=>this.selectAssetExpired(element[0])}>
               
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[3]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Burnable balance: {outputNumber(element[1],7)}(long) / {outputNumber(element[2],7)}(short)</div>
                        <div className="col text-right">UL: {outputNumber(element[4]/1000,0)}</div>
                    </div>
            </div>
        );
        return(burnableAssets)
    }

    

    const selectAssetExpired = async(asset) =>{
        console.log(asset)
        await this.setState({"selectedAssetExpired":asset});
        let token1Address = assetDetails[selectedAssetExpired][0]
        let token2Address = assetDetails[selectedAssetExpired][1]
        let tokenContract1 = new web3_nm.eth.Contract(ERC20_ABI,token1Address)
        let tokenContract2 = new web3_nm.eth.Contract(ERC20_ABI,token2Address)
        let balanceToken1 = await tokenContract1.methods.balanceOf(address).call()
        let balanceToken2 = await tokenContract2.methods.balanceOf(address).call()
        await this.setState({burnableBalanceToken1: balanceToken1})
        await this.setState({burnableBalanceToken1Visible: true})
        await this.setState({burnableBalanceToken2: balanceToken2})
        await this.setState({burnableBalanceToken2Visible: true})
        await this.setState({token1Address})
        await this.setState({token2Address})
        console.log(balanceToken1)
        console.log(balanceToken2)
        await sleep(50) 
        await this.checkInputExpired()
        this.closeChooseExpiredAssetModal();
    }

        
        const tooltip1 = props => (
            <Tooltip {...props}>
            You can "burn" TWIN Asset Tokens and redeem those against {stableCoinName} stable coins at any time, if you provide an equal amount of long amd short tokens (from your liquid portfolio positions). The amount of {stableCoinName} stable coins you will receive is the (equal) amount of long and short tokens burned multiplied by the upper limit of the respective TWIN Asset minus a 2% early redemption fee.</Tooltip>
        );
        const tooltip2 = props => (
            <Tooltip {...props}>
            When an asset is expired, you can burn your long and short token balance also after the expiry date and redeem {stableCoinName} stable coins. The amount of {stableCoinName} stable coins you will receive ist the amount of long tokens multiplied with the asset price at expiry time plus the amount of short tokens multiplied with the difference of the upper limit of the asset and the asset price at expiry.</Tooltip>
        );
        
        return ( 
            <div className="mainContainerMobile">
                <Modal show={chooseAssetModalOpen} onHide={closeChooseAssetModal}>
                    <Modal.Header className="" closeButton>
                        <Modal.Title>Select TWIN Asset pair to burn</Modal.Title> 
                    
                    </Modal.Header>
                    
                    <Modal.Body className="" style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                        }} 
                    >
                        <div className="row p-3 pr-3 my-auto">
                            <input className="col w-100 searchField" id="search" placeholder="Search" onChange={() =>filterAssets()}></input>
                            
                        </div>
                        <div className="list-group">
                            {listBurnableAssets()}
                        </div>
                    </Modal.Body>
                    
                </Modal>

                <Modal show={chooseExpiredAssetModalOpen} onHide={closeChooseExpiredAssetModal}>
                    <Modal.Header className="" closeButton>
                        <Modal.Title>Select TWIN Asset pair to burn</Modal.Title>   
                    </Modal.Header>
                    
                    <Modal.Body className="" style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                        }} 
                    >
                        <div className="row p-3 pr-3 my-auto">
                            <input className="col w-100 searchField" id="search" placeholder="Search" onChange={() =>filterAssets()}></input>
                            
                        </div>
                        <ul className="list-group">
                            {listBurnableExpiredAssets()}
                        </ul>
                    </Modal.Body>
                    
                </Modal>


                <Modal show={modalOpen} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Burn assets and redeem {stableCoinName}:</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>You will burn {burnAmount} {tokenToBurn} and i{tokenToBurn} token.</Modal.Body>
                        <Modal.Body>You will receive {outputNumber(amountToGet*0.98,2)} {stableCoinName}</Modal.Body>
                    <Modal.Footer>
                    <TransactionButton
                        abi={assetFactory_ABI} // Adjust this to where you're storing ABI
                        contractAddress={assetFactory_Address} 
                        functionName="burnAssets" 
                        args={[tokenToBurn, burnAmount*1e18] } // Example, adjust as needed
                        text="Burn"
                        onSuccess={onSuccessBurn}
                        gasLimit={600000}
                    />
                    </Modal.Footer>
                </Modal>

                <Modal show={modalBurnExpiredAssetsOpen} onHide={closeModalBurnExpiredAssets}>
                    <Modal.Header closeButton>
                        <Modal.Title>Burn assets and redeem {stableCoinName}:</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>You will burn {amountToBurnLong} {tokenToBurn} and {amountToBurnShort} i{tokenToBurn} token.</Modal.Body>
                        <Modal.Body>You will receive {outputNumber(amountToGetExpired,2)} {stableCoinName}</Modal.Body>
                    <Modal.Footer>
                        <div>
                        <TransactionButton
                            abi={assetFactory_ABI} // Adjust this to where you're storing ABI
                            contractAddress={assetFactory_Address} 
                            functionName="burnAssets" 
                            args={[tokenToBurn, burnAmount*1e18] } // Example, adjust as needed
                            text="Burn"
                            onSuccess={onSuccessBurn}
                        />
                        </div>
                    </Modal.Footer>
                </Modal>

                <div className="middleBoxSmallMobile">
                        <div className="mainBox">
                            <div className="selectButtonRow">
                                <div className={style1} role="button" onClick={selectLiveView}>
                                    Live&nbsp;
                                    <OverlayTrigger placement="right" overlay={tooltip1}>
                                        <InfoOutlinedIcon />
                                    </OverlayTrigger>
                                </div>
                                <div className={style2} role="button" onClick={selectExpiredView}>
                                    Expired&nbsp;
                                    <OverlayTrigger placement="right" overlay={tooltip2}>
                                        <InfoOutlinedIcon />
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="redeemHeadline">
                                Select a TWIN asset pair to redeem
                            </div>
                            
                                {
                                view === "live" ? 
                                        <div className="subBox">
                                                <div className="col align-self-end text-lg-right">
                                                    {burnableBalanceVisible ? 
                                                        <div role="button">Balance available: <span className="tradeBalance textBalance" onClick={setMaxAmount}>{outputNumber(parseInt(selectedAssetBurnableBalance)/1e18,8)} (Max)</span></div>
                                                        :
                                                        <div></div>
                                                    }
                                                </div>
                                            <div className="tradingBoxRow">
                                                <div className="">
                                                    <input placeholder="0.00" className="inputCustomMobile" type='text' id='amountToBurn' onChange={() =>checkInputLive()} />
                                                </div>
                        
                                                <div className="selectAssetButtonMobile" type="button" onClick={openChooseAssetModal}>
                                                <div className='assetBoxIcon'>
                                                    <img className="" src={assetIcon} alt="honey" width="24px"/>
                                                </div>
                                                <div>{selectedAsset} <KeyboardArrowDownOutlinedIcon />   </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col align-self-start py-4">
                                                    {burnAmount > allowance && typeof(selectedAsset) !== 'undefined' ? 
                                                        <div>
                                                            <TransactionButton
                                                                contractAddress={assetDetails[selectedAsset][0]} 
                                                                abi = {ERC20_ABI}
                                                                functionName= 'approve'
                                                                args =  {[assetFactory_nm._address,web3_nm.utils.toBigInt(2**255)]} 
                                                                text='Approve Long Token'
                                                                onSuccess={onSuccessApprove}
                                                            />
                                                            </div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {burnAmount > allowance2 && typeof(selectedAsset) !== 'undefined' ?  
                                                        <div>
                                                            <TransactionButton
                                                                contractAddress={assetDetails[selectedAsset][1]} 
                                                                abi = {ERC20_ABI}
                                                                functionName= 'approve'
                                                                args =  {[assetFactory_nm._address,web3_nm.utils.toBigInt(2**255)]} 
                                                                text='Approve Short Token'
                                                                onSuccess={onSuccessApprove}
                                                            />
                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {submitButtonDeactivated && burnAmount < allowance2 && burnAmount < allowance2? 
                                                        <div className="issuaaButtonDeactivated">{submitButtonDeactivatedMessage}</div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {burnAmount < allowance2 && burnAmount < allowance2 && submitButtonDeactivated === false ? 
                                                        <div role="button" className="issuaaButton" onClick={openModal}>Submit</div>
                                                        :
                                                        <div></div>
                                                    }
                        
                                                </div>    
                                            </div> 
                                            
                                        </div>
                                        
                                    :
                                    <div className="subBox">
                                            
                                                <div className='tradingBoxRow'>
                                                    <div role="button" className="selectAssetButtonMobile w-100"onClick={openChooseExpiredAssetModal}>
                                                        <div>
                                                            {selectedAssetExpired} <KeyboardArrowDownOutlinedIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            <div className="row mt-4 px-4">
                                                <div className="col align-self-end text-lg-right textBalance">
                                                    {burnableBalanceToken1Visible ? 
                                                        <div>Balance available to burn: <span role="button" onClick={setMaxAmountToken1}>{outputNumber(parseInt(burnableBalanceToken1)/1e18,8)}</span></div>
                                                        :
                                                        <div></div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row px-4">     
                                                {selectedAssetExpired !== "Select Asset" ?
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" for="amountToBurnToken1">Amount to convert {selectedAssetExpired}:</label>
                                                    </div>
                                                    <input className="form-control bg-innerBox" onChange={() =>checkInputExpired()} type='number' id='amountToBurnToken1' defaultValue={0}></input>
                                                    
                                                </div>
                                                :
                                                ''
                                                }   
                                            </div>   
                                            <div className="row px-4">
                                                <div className="col align-self-end text-lg-right textBalance">
                                                    {burnableBalanceToken1Visible ? 
                                                        <div>Balance available to burn: <span role="button" onClick={setMaxAmountToken2}>{outputNumber(parseInt(burnableBalanceToken2)/1e18,8)}</span></div>
                                                        :
                                                        <div></div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row px-4">     
                                                {selectedAssetExpired !== "Select Asset" ?
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" for="amountToBurnToken2">Amount to convert i{selectedAssetExpired}:</label>
                                                    </div>
                                                    <input className="form-control bg-innerBox" onChange={() =>checkInputExpired()} type='number' id='amountToBurnToken2' defaultValue={0}></input>
                                                    
                                                </div>
                                                :
                                                ''
                                                }   
                                            </div> 
                                            
                                            <div className="row">
                                                <div className="col align-self-start py-4">
                                                    {burnAmount > allowance && selectedAssetExpired !== 'Select Asset' ?  
                                                        <div id="buttonRounded">
                                                            <TransactionButton
                                                                contractAddress={assetDetails[selectedAssetExpired][0]} 
                                                                abi = {ERC20_ABI}
                                                                functionName= 'approve'
                                                                args =  {[assetFactory_nm._address,web3_nm.utils.toBigInt(2**255)]} 
                                                                text="Approve Long"
                                                                onSuccess={onSuccessApprove}
                                                            />
                                                            
                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {burnAmount > allowance2 && selectedAssetExpired !== 'Select Asset' ?  
                                                        <div id="buttonRounded">
                                                            <TransactionButton
                                                                contractAddress={assetDetails[selectedAssetExpired][1]} 
                                                                abi = {ERC20_ABI}
                                                                functionName= 'approve'
                                                                args =  {[assetFactory_nm._address,web3_nm.utils.toBigInt(2**255)]} 
                                                                text='Approve Short Token'
                                                                onSuccess={onSuccessApprove}
                                                            />
                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {submitButtonDeactivated ? 
                                                        <div className="issuaaButtonDeactivated">{submitButtonDeactivatedMessage}</div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {submitButtonDeactivated === false && burnAmount < allowance && burnAmount < allowance2  ? 
                                                        <div role="button" className="issuaaButton" onClick={openModalBurnExpiredAssets}>Submit</div>
                                                        :
                                                        <div></div>
                                                    }
                        
                                                </div>    
                                            </div> 
                        
                                                
                                        </div>
                                        
                                }
                             
                        </div>
                   
                    
                </div>
            
            </div> 

        );
    
}
 
export default MobileBurnAssets;