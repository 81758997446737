
import React, {useEffect, useState, createContext} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AppProvider } from './context/AppContext';
import { useViewport } from './context/AppContext'; // Adjust the import if needed

import Desktop from './components/Desktop';
import Mobile from './components/Mobile';


import { Routes, Route } from 'react-router-dom';




const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  return width < breakpoint ? <Mobile /> : <Desktop />;
};

function App() {
  return (
    <AppProvider> {/* AppProvider should wrap your app here */}
      <Routes>
        <Route path="*" element={<MyComponent />} />
      </Routes>
    </AppProvider>
  );
}
export default App;
