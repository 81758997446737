import '../../../styles/navbar.css';
import React from 'react';
import ConnectButton from './ConnectButton';

import { useAppContext } from '../../../context/AppContext'; // Import useAppContext


const Navbar = () => {
  const {
    activePage
} = useAppContext();

    return (
        <div className="navbarDesktop">
          <div className="navbarHeadline">{activePage}</div>
          
          <div className='connectButtonDesktop'>
            <ConnectButton />
          </div>
          
        </div>
    )
  }
  export default Navbar;