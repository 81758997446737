import '../../../styles/governanceToken.css';
import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {TransactionButton} from '../../../functions/Trx';
import { useAppContext } from '../../../context/AppContext'; // Import the useAppContext hook

const GovernanceTokenPage = () => {
    // Access context values
    const {
        web3_nm,
        GovernanceToken_nm,
        GovernanceTokenBalance,
        GovernanceToken_Address,
        ERC20_ABI,
        outputNumber,
        address,
        roundDown,
        VotingEscrow_nm,
        lockAmount,
        lockDate,
        updateISSData,
        VotingEscrow_Address,
        VotingEscrow_ABI,
        GovernanceTokenTotalBalanceAllChains,
        GovernanceTokenStakeBalance,
        veISSBalance,
        timeStampToDate,
        TotalVeISSSupply,
        ISSPrice,
        ISSSupplyCurrent,
        
    } = useAppContext();

    const nowTimeStamp = new Date().getTime()
    const now = new Date().getTime()/1000
    const startTimeStamp = parseInt(nowTimeStamp/(7*86400*1000))*(7*86400*1000)+(7*86400*1000)
    const endDateTimeStamp = nowTimeStamp + (4 * 365 * 86400*1000)
    const maxDate = new Date(endDateTimeStamp )
    
    const [selectedDate, setSelectedDate] = useState(new Date(startTimeStamp));
    const [newLockTime, setNewLockTime] = useState(startTimeStamp);
    const [stakeModalOpen, setStakeModalOpen] = useState();
    const [unstakeModalOpen, setUnstakeModalOpen] = useState(false);
    const [createLockModalOpen, setCreateLockModalOpen] = useState(false);
    const [extendLockModalOpen, setExtendLockModalOpen] = useState(false);
    const [increaseLockModalOpen, setIncreaseLockModalOpen] = useState(false);
    const [chooseChainModalOpen, setChooseChainModalOpen] = useState(false);
    const [startDate, setStartDate] = useState(false);
    
    const [amountToLock, setAmountToLock] = useState(0);
    const [VotingEscrowApproved, setVotingEscrowApproved] = useState(new Date(startTimeStamp));
    const [VotingEscrowAllowance, setVotingEscrowAllowance] = useState(0);


   const setDate = (date) => {
       console.log(date)
       setSelectedDate(date)
       setNewLockTime(date.getTime())
    }

    useEffect(() => {
        checkVotingEscrowAllowance()
    }, [VotingEscrow_nm]);


  
   const checkVotingEscrowAllowance = async () => {
       if (typeof(VotingEscrow_nm) != 'undefined'){
           // check if the the allowance has been set for the VotingEscrow contract to spend TWIN
           let addressToApprove = VotingEscrow_nm._address
           let allowance = await GovernanceToken_nm.methods.allowance(address, addressToApprove).call()
           console.log(allowance)
           setVotingEscrowAllowance(allowance)
           if (allowance > 100000000 * 1e18) {
            setVotingEscrowApproved(true)
           }
           else {
            setVotingEscrowApproved(false)
           }
       }
   };


    const openCreateLockModal = async () => {
        setCreateLockModalOpen(true)
   };
   const closeCreateLockModal = () => setCreateLockModalOpen(false);


   const openExtendLockModal = async () => {
       let _startDate = new Date((parseInt(lockDate)+691200)*1000)
       console.log(_startDate)
       setStartDate(_startDate)
       setSelectedDate(_startDate)
       setExtendLockModalOpen(true)
   };
   const closeExtendLockModal = () => setExtendLockModalOpen(false);


   const openIncreaseLockModal = async () => {
       setIncreaseLockModalOpen(true)
   };
   const closeIncreaseLockModal = () => setIncreaseLockModalOpen(false);


   const checkLockAmount = async() =>{
       let amountToLock = document.getElementById('amountToLock').value
       var amountRaw = web3_nm.utils.toWei(amountToLock.toString(), 'ether')
       var _lockAmount = web3_nm.utils.toBigInt(amountRaw)
       setAmountToLock(_lockAmount)
   }


   const checkIncreaseLockAmount = async() =>{
       console.log("DEBUG")
       let amountToLock = document.getElementById('amountToLock').value
       var amountRaw = web3_nm.utils.toWei(amountToLock.toString(), 'ether')
       var _lockAmount = web3_nm.utils.toBigInt(amountRaw)
       setAmountToLock(_lockAmount)
   }


   const onSuccessApprove = async() =>{
       checkVotingEscrowAllowance()
   }

   const onSuccessLock = async() => {
       closeCreateLockModal()
       await updateISSData();
   }

   const onSuccessLockTime = async() => {
       closeExtendLockModal()
       await updateISSData();
   }


   const onSuccessLockAmount = async() => {
       closeIncreaseLockModal()
       await updateISSData();
      
   }

   const onSuccessWithdraw = async() =>{ 
       await updateISSData();
   }

   

   const setMaxLockAmount = async() =>{
       let amount = 0
       if (typeof(GovernanceTokenBalance) != 'undefined'){
           amount = parseFloat(GovernanceTokenBalance * 0.999999999)
       }
       document.getElementById('amountToLock').value = roundDown(amount,12);
       await checkLockAmount()
       return
   }


   
       const tooltip1 = props => (
           <Tooltip {...props}>
           TWIN tokens must be staked and locked in order to participate in governance votings.
           </Tooltip>
       );

       const tooltip4 = props => (
           <Tooltip {...props}>Amount of TWIN currently outstanding</Tooltip>
       );
       const tooltip5 = props => (
           <Tooltip {...props}>
           The maximum amount of TWIN is limited at 100m. All TWIN tokens are pre-mineds.
           </Tooltip>
       );
       const tooltip6 = props => (
           <Tooltip {...props}>
           Total value of TWIN circulating, i.e.: Amount of TWIN currently outstanding multiplied by current TWIN price.
           </Tooltip>
       );
       const tooltip7 = props => (
           <Tooltip {...props}>
           Max. possible supply of TWIN (@100m) multiplied by current TWIN price.
           </Tooltip>
       );

      
      
       return (
          






           <div className="container-fluid w-100">
               <Modal show={createLockModalOpen} onHide={closeCreateLockModal}>
                   <Modal.Header closeButton>
                       <Modal.Title>Lock TWIN Tokens</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>
                       
                           <div className="governanceTokenLockAmountRow">
                               <div className="" for="amountToLock">Amount to lock:</div>
                               <input className="inputAmount" type='number' onChange={() =>checkLockAmount()} id='amountToLock'></input>
                           </div>
                           
                           <div className="">
                               <div onClick={setMaxLockAmount} role="button" className="" for="amountToLock">Balance: {outputNumber(GovernanceTokenBalance,0)} Max</div>
                           </div>
                       
                        <div className="governanceTokenDateAmountRow">
                            <div className="" for="lockDate">Lock until:</div>
                            <div className="" id="lockDate">
                               <DatePicker
                                 selected={ selectedDate }
                                 minDate={startDate}
                                 maxDate={maxDate}
                                 onChange={ setDate }
                                 name="startDate"
                                 dateFormat="MM/dd/yyyy"
                                 className='inputDate'
                               />
                            </div>
                       </div>
                       {VotingEscrowAllowance < amountToLock
                           ?

                               <TransactionButton
                                   contractAddress={GovernanceToken_Address}
                                   abi = {ERC20_ABI}
                                   functionName= 'approve'
                                   args =  {[VotingEscrow_Address,web3_nm.utils.toBigInt(2**255)]} // Example, adjust as needed
                                   text="Approve"
                                   onSuccess={onSuccessApprove}
                               />
                           :
                           ''
                       }
                       {VotingEscrowAllowance >= amountToLock && (GovernanceTokenBalance * 1e18 < amountToLock) ===  false && amountToLock > 0
                            ?
                            <TransactionButton
                                abi={VotingEscrow_ABI}
                                contractAddress={VotingEscrow_Address}
                                functionName="createLock"
                                args={[amountToLock,parseInt(newLockTime/1000)]}
                                text="Lock"
                                onSuccess = {onSuccessLock}
                            />
                        
                            :''
                        }
                        {GovernanceTokenBalance * 1e18 < amountToLock
                            ?
                            <div className="issuaaButtonDeactivated">
                                Amount too high
                            </div>
                            :''
                        }
                   </Modal.Body>
                   
               </Modal>


               <Modal show={extendLockModalOpen} onHide={closeExtendLockModal}>
                   <Modal.Header closeButton>
                       <Modal.Title>Extend the lock time of your TWIN Tokens</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>
                        <div className="governanceTokenDateAmountRow">
                            <div className="" for="lockDate">Lock until:</div>
                            <div className="" id="lockDate">
                               <DatePicker
                                 selected={ selectedDate }
                                 minDate={startDate}
                                 maxDate={maxDate}
                                 onChange={ setDate }
                                 name="startDate"
                                 dateFormat="MM/dd/yyyy"
                                 className='inputDate'
                               />
                            </div>
                       </div>
                       <div>
                           <TransactionButton
                               abi={VotingEscrow_ABI}
                               contractAddress={VotingEscrow_Address}
                               functionName="increaseLockTime"
                               args={[parseInt(parseInt(newLockTime)/1e3)]}
                               text="Extend lock time"
                               onSuccess = {onSuccessLockTime}
                           />
                       </div>
                   </Modal.Body>
                   
               </Modal>


               <Modal show={increaseLockModalOpen} onHide={closeIncreaseLockModal}>
                   <Modal.Header closeButton>
                       <Modal.Title>Increase the amount of locked TWIN Tokens</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>
                        <div className="governanceTokenLockAmountRow">
                            <div className="" for="amountToLock">Amount to lock:</div>
                            <input className="inputAmount" type='number' onChange={() =>checkLockAmount()} id='amountToLock'></input>
                        </div>
                        
                        <div className="">
                            <div onClick={setMaxLockAmount} role="button" className="" for="amountToLock">Balance: {outputNumber(GovernanceTokenBalance,0)} Max</div>
                        </div>    
                        {VotingEscrowApproved === true && (GovernanceTokenBalance * 1e18 < amountToLock) ===  false && amountToLock > 0
                            ?
                            <TransactionButton
                                abi={VotingEscrow_ABI}
                                contractAddress={VotingEscrow_Address}
                                functionName="increaseLockAmount"
                                args={[amountToLock]}
                                text="Increase locked amount"
                                onSuccess = {onSuccessLockAmount}
                            />
                            :''
                        }
                        {GovernanceTokenBalance * 1e18 < amountToLock
                                ?
                                <Button className="btn w-100 issuaaButtonDisabled" disabled>
                                    Amount too high
                                </Button>
                                :''
                        }                   
                   </Modal.Body>
                   
               </Modal>




               <div className="mainContainer">
                   
                    <div className="middleBoxSmall">
                        <div className="subBox subBoxGovernanceToken">
                            <div className="governanceTokenFirstRow">
                                <div className="">Liquid TWIN balance:</div>
                                <div className='dots'></div>
                                <div className="boldGreen">{outputNumber(parseFloat(GovernanceTokenBalance),0)}</div>                                            
                            </div>
                            <div className="governanceTokenMiddleRow">
                                <div className="">Locked TWIN balance:</div>
                                <div className='dots'></div>
                                <div className="boldGreen">{outputNumber(parseFloat(GovernanceTokenTotalBalanceAllChains-GovernanceTokenBalance),0)}</div>                                            
                            </div>
                            <div className="governanceTokenRow governanceTokenLastRow">
                                <div className="boldGreen">Total TWIN balance:</div>
                                <div className='dots'></div>
                                <div className="boldGreen">{outputNumber(parseFloat(GovernanceTokenTotalBalanceAllChains),0)}</div>                                            
                            </div>
                        </div>
                                  
                        <div className="subBox subBoxGovernanceToken">
                            <div className="governanceTokenRow governanceTokenFirstRow">
                                <div className="">Your veTWIN balance:</div>
                                <div className='dots'></div>
                                <div className="boldGreen">{outputNumber(parseFloat(veISSBalance),0)}</div>                                            
                            </div>
                            <div className="governanceTokenRow governanceTokenMiddleRow">
                                <div className="">
                                    Current lock ends on
                                </div>
                                <div className='dots'></div>
                                <div className="boldGreen">
                                    {(lockDate   > now
                                        ?
                                        timeStampToDate(lockDate,0)
                                        :
                                        'n.a.'
                                    )}
                                </div>                                            
                            </div>
                            {(lockDate   < now && GovernanceTokenStakeBalance >1
                                ?
                                <div className="w-100">
                                    <TransactionButton
                                        abi={VotingEscrow_ABI}
                                        contractAddress={VotingEscrow_Address}
                                        functionName="increasewithdrawockAmount"
                                        args={[]}
                                        text="Withdraw TWIN"
                                        onSuccess = {onSuccessWithdraw}
                                    />
                                    
                                </div>
                                :
                                ''
                            )}

                            <div className='buttonRow'>
                            {(GovernanceTokenBalance   >0.00011 && lockDate   > now
                                ?
                                
                                    <div className="lockButton" onClick={openIncreaseLockModal}>Increase locked amount<KeyboardArrowDownOutlinedIcon /></div>
                                
                                :
                                ''
                            )}


                            {(lockDate < 1
                                ?
                                    <div className="lockButton" role="button" onClick={openCreateLockModal}>Create a lock <KeyboardArrowDownOutlinedIcon /></div>
                                :
                                ''
                            )}


                            {(veISSBalance > 0
                                ?
                               
                                    <div className="lockButton" role="button" onClick={openExtendLockModal}>Extend lock time <KeyboardArrowDownOutlinedIcon /></div>
                                
                                :
                                ''
                            )}
                            </div>
                            <div className="governanceTokenRow governanceTokenLastRow">
                                <div className="boldGreen">Total veTWIN supply:</div>
                                <div className="boldGreen">{outputNumber(parseFloat(TotalVeISSSupply),0)}</div>                                            
                            </div>
                            
                        </div>
                        <div className="subBox subBoxGovernanceToken">
                            <div className="governanceTokenRow governanceTokenFirstRow">
                                <div>TWIN circulating</div>
                                <div className='dots'></div>
                                <div className="">{outputNumber(ISSSupplyCurrent/1000000,1)}m</div>                                            
                            </div>
                            <div className="governanceTokenRow governanceTokenMiddleRow">
                                <div>TWIN total max. supply</div>
                                <div className='dots'></div>
                                <div className="">{outputNumber(100,1)}m</div>                                            
                            </div>
                            <div className="governanceTokenRow governanceTokenMiddleRow">
                                <div className="">Current TWIN market cap</div>
                                <div className='dots'></div>
                                <div className="">USD {outputNumber(ISSPrice * ISSSupplyCurrent/1000000,1)}m</div>                                            
                            </div>
                            <div className="governanceTokenRow governanceTokenLastRow">
                                <div className="boldGreen">Diluted TWIN market cap</div>
                                <div className="boldGreen">USD {outputNumber(ISSPrice * 100000000/1000000,1)}m</div>                                            
                            </div>
                        </div>
                    </div>
               </div>
           </div>
        );
   }

export default GovernanceTokenPage;

