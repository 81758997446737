import '../../../styles/pool.css';
import React, { useEffect,useState } from 'react';
import { Modal} from "react-bootstrap";
import { Gear } from 'react-bootstrap-icons';
import {TransactionButton} from '../../../functions/Trx'; 
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import assetIcon from '../../../img/icons/Avatar.png';
import honeyIcon from '../../../img/icons/HoneyIcon.png';


import { useAppContext } from '../../../context/AppContext'; // Import the useAppContext hook

const MobilePool = () => {
    // Access context values
    const {
        assetDetails,
        pools,
        web3_nm,
        GovernanceTokenBalance,
        GovernanceToken_Address,
        MarketFactory_nm,
        MarketPair_ABI,
        ERC20_ABI,
        USDC_Address,
        outputNumber,
        stableCoinName,
        address,
        MarketRouter_Address,
        slippage,
        trxTime,
        updateAssetBalanceWithAddress,
        loadUSDBalance,
        updateLPPair,
        updatePortfolioValue,
        USDCBalance,
        USDDecimals,
        sleep,
        MarketRouter_ABI,
        saveSlippagePreference,
        saveTrxTimePreference,
        roundDown
    } = useAppContext();

    // State variables
    const [assets, setAssets] = useState([]);
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [assetBalances, setAssetBalances] = useState({});
    const [assetAddresses, setAssetAddresses] = useState({});
    const [chooseAssetModalOpen, setChooseAssetModalOpen] = useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [wrongSlippageInputMessage, setWrongSlippageInputMessage] = useState();
    const [highSlippageInputMessage, setHighSlippageInputMessage] = useState();
    const [wrongTrxTimeInputMessage, setWrongTrxTimeInputMessage] = useState();
    const [filteredLPAssets, setFilteredLPAssets] = useState([]);
    const [chooseLPTokenModalOpen, setChooseLPTokenModalOpen] = useState();
    const [allowanceToken, setAllowanceToken] = useState();
    const [allowanceUSDC, setAllowanceUSDC] = useState();
    const [allowanceLPToken, setAllowanceLPToken] = useState();
    const [selectedAssetAddress, setSelectedAssetAddress] = useState();
    const [selectedAsset, setSelectedAsset] = useState('Select Asset');
    const [selectedAssetBalance, setSelectedAssetBalance] = useState();
    const [token0, setToken0] = useState();
    const [selectedLPTokenBalance, setSelectedLPTokenBalance] = useState();
    const [selectedAssetPrice, setSelectedAssetPrice] = useState();
    const [approvalButtonTokenVisible, setApprovalButtonTokenVisible] = useState();
    const [approvalButtonUSDCVisible, setApprovalButtonUSDCVisible] = useState();
    const [addLiquidityButtonVisible, setAddLiquidityButtonVisible] = useState(true);
    const [removeLiquidityButtonVisible, setRemoveLiquidityButtonVisible] = useState(false);
    const [buttonMessage, setButtonMessage] = useState();
    const [selectedLPToken, setSelectedLPToken] = useState();
    const [selectedLPTokenAddress, setSelectedLPTokenAddress] = useState();
    const [token1Ratio, setToken1Ratio] = useState();
    const [token2Ratio, setToken2Ratio] = useState();
    const [approvalButtonLPTokenVisible, setApprovalButtonLPTokenVisible] = useState();
    const [selectedLPPairAddress, setSelectedLPPairAddress] = useState();
    const [errorButtonVisible, setErrorButtonVisible] = useState();
    const [expectedOutputVisible, setExpectedOutputVisible] = useState();
    const [tokenAmountA, setTokenAmountA] = useState();
    const [tokenAmountB, setTokenAmountB] = useState();
    const [selectedLPTokenAmount, setSelectedLPTokenAmount] = useState();
    const [estimatedOutput1, setEstimatedOutput1] = useState();
    const [estimatedOutput2, setEstimatedOutput2] = useState();
    const [amountADesired, setAmountADesired] = useState();
    const [amountBDesired, setAmountBDesired] = useState();
    const [amountAMin, setAmountAMin] = useState();
    const [amountBMin, setAmountBMin] = useState();    
    const [deadline, setDeadline] = useState();  
    const [liquidityToRemove, setLiquidityToRemove] = useState();
    const [showAddLiquidyPart, setShowAddLiquidyPart] = useState(true);
    const [showRemoveLiquidyPart, setShowRemoveLiquidyPart] = useState();
    const [style2, setStyle2] = useState('selectButtonUnselected');
    const [style1, setStyle1] = useState('selectButtonSelected');
    
    
    
    

    useEffect(() => {
        const initialize = async () => {
            const locationUnSplit = window.location.pathname;
            if (locationUnSplit) {
                const locationSplit = locationUnSplit.split("/");
                const buyOrSellVisible = locationSplit[2];
                const pairAddress = locationSplit[3];
                if (buyOrSellVisible === 'remove') {
                    showRemoveLiquidity();
                }
                initiatiatePage(pairAddress, buyOrSellVisible);
            }
        };
        initialize();
    }, []); // Empty dependency array means this runs once on component mount

    

    const initiatiatePage = async(pairAddress,buyOrSellVisible) =>{
        console.log(pairAddress)
        try{
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI,pairAddress)
            let token0 = await MarketPair.methods.token0().call();
            let token1 = await MarketPair.methods.token1().call();
            let TokenContract0 = new web3_nm.eth.Contract(ERC20_ABI,token0)
            let tokenSymbol0 = await TokenContract0.methods.symbol().call()
            let TokenContract1 = new web3_nm.eth.Contract(ERC20_ABI,token1)
            let tokenSymbol1 = await TokenContract1.methods.symbol().call()
            let selectedAsset
            let assetAddress
            if (tokenSymbol0 === 'USDC'){
                selectedAsset = tokenSymbol1
                assetAddress = token1
            }
            else{
                selectedAsset = tokenSymbol0
                assetAddress = token0
            }
            console.log(selectedAsset)
            if (buyOrSellVisible === 'remove'){
                selectLPInitial(selectedAsset, assetAddress, pairAddress)
            }
            else{
                selectAssetInitial(selectedAsset, assetAddress, pairAddress)
            }
            }
        catch(err){
            console.log(err.message)
            return
        }
        
    }

    const closeChooseAssetModal = () => setChooseAssetModalOpen(false);
    const convertBigInt = (number) => {
        const regularNumber = parseFloat(number);
        // Round the regular number to an integer
        const integerValue = Math.round(regularNumber);
        const bigIntValue = web3_nm.utils.toBigInt(integerValue)
        return bigIntValue;
    }
    
    

    const openSettingsModal = () => setSettingsModalOpen(true)
    const closeSettingsModal = () => setSettingsModalOpen(false)
    const checkSlippageInput = () => {
        console.log("Checking slippage input")
        try{
            let slippage = document.getElementById('slippage').value * 100
            console.log(slippage)
            if (isNaN(slippage)) {
                setWrongSlippageInputMessage(true)        
                return false
            }
            if (slippage < 0 || slippage > 5000) {
                setWrongSlippageInputMessage(true)
                setHighSlippageInputMessage(false)       
                return false
            }
            if (slippage > 500 && slippage < 5000) {
                setWrongSlippageInputMessage(false)
                setHighSlippageInputMessage(true)        
                return false
            }
                setWrongSlippageInputMessage(false)
                setHighSlippageInputMessage(false)    
            return true
            }
            catch{
                return false
            }
    }

    const saveSettingsSlippage=async()=>{
        let check = checkSlippageInput()
        if (check) {
            let slippage = document.getElementById('slippage').value * 100
            await saveSlippagePreference(slippage)
                       
        }
    }

    const checkTrxTimeInput = () => {
        console.log("Checking trx time input")
        try{
            let trxTime = document.getElementById('trxTime').value
            console.log(trxTime)
            if (isNaN(trxTime)) {
                setWrongTrxTimeInputMessage(true)     
                return false
            }
            if (trxTime <0 || trxTime > 600) {
                setWrongTrxTimeInputMessage(true)     
                return false
            }
            setWrongTrxTimeInputMessage(false)  
            return true
            }
            catch{
                return false
            }
    }

    const checkApproval = async(tokenAddress, approvalAddress) =>{  
        console.log(tokenAddress)
        console.log(approvalAddress)
        console.log(ERC20_ABI)
        let tokenContract = new web3_nm.eth.Contract(ERC20_ABI,tokenAddress)
        var amountRaw = "100000000000000000000000000000"
        var amount = web3_nm.utils.toBigInt(amountRaw)
        console.log(address)
        console.log(approvalAddress)
        let allowance = await tokenContract.methods.allowance(address, approvalAddress).call()
        if (parseInt(allowance) < parseInt(amount)){
            return(false)
        }
        else {return (true)}
    };

    const saveSettingsTrxTime=async()=>{
        let check = checkTrxTimeInput()
        console.log(check)
        if (check) {
            let trxTime = document.getElementById('trxTime').value
            await saveTrxTimePreference(trxTime)
            
        }
    }



    

    const filterAssets = () =>{
        let _availableAssets = []
        for (let i = 0; i < assets.length; ++i) {
            console.log(assets[i])
            if (assets[i][1] >0){
                _availableAssets.push(assets[i])
            }
        }
        let _filteredAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < _availableAssets.length; ++i) {
            if (_availableAssets[i][2].toLowerCase().includes(searchTerm) || _availableAssets[i][0].toLowerCase().includes(searchTerm)){
                _filteredAssets.push(_availableAssets[i])
            }
            
        }
        setFilteredAssets(_filteredAssets)
    }

    ///CAN BE REMOVED? 
    const filterLPAssets = ()  =>{
        let availableAssets = []
        for (let i = 0; i < assets.length; ++i) {
            console.log(assets[i])
            if (assets[i][1] >0){
                availableAssets.push(assets[i])
            }
        }

        let _filteredLPAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < availableAssets.length; ++i) {
            if (availableAssets[i][2].toLowerCase().includes(searchTerm) || availableAssets[i][0].toLowerCase().includes(searchTerm)){
                filteredLPAssets.push(availableAssets[i])
            }
            
        }
        setFilteredLPAssets(_filteredLPAssets)

    }
    const openSelectAssetModal= async()=>{
        let _assets = [];
        let _assetBalances = {};
        let _assetAddresses = {};
        console.log(pools)
        console.log(_assets)
        console.log(assetDetails)
        for (let i = 0; i < pools.length; ++i) {
            let _ticker = pools[i][0]
            let _balance
            try {
                _balance = assetDetails[_ticker]['tokenBalance1']
                console.log(_balance)
            }
            catch{
                try{_balance=assetDetails[_ticker.slice(1)]['tokenBalance2']}
                catch{_balance = GovernanceTokenBalance}
            }
            let _name = pools[i][3]
            let _lpBalance = pools[i][4]
            console.log(_lpBalance)
            _assets.push([_ticker,_balance,_name,_lpBalance])
            _assetBalances[_ticker] = _balance
            _assetAddresses[_ticker] = pools[i][11]
        }
        
        
        let _filteredAssets =[];
        for (let i = 0; i < _assets.length; ++i) {
            console.log(_assets[i])
            _filteredAssets.push(_assets[i])
        }
        setAssets(_assets)
        setFilteredAssets(_filteredAssets)
        setAssetBalances(_assetBalances)
        setAssetAddresses(_assetAddresses)
        setChooseAssetModalOpen(true)
        console.log(_assets)   
    };

    const openChooseLPTokenModal= async()=>{
        let _assets = [];
        let _assetBalances = {};
        let _assetAddresses = {};
        console.log("Debug")
        console.log(pools)
        console.log(_assets)
        console.log(assetDetails)
        for (let i = 0; i < pools.length; ++i) {
            let _ticker = pools[i][0]
            let _balance
            try {
                _balance = assetDetails[_ticker]['tokenBalance1']
                console.log(_balance)
            }
            catch{
                try{_balance=assetDetails[_ticker.slice(1)]['tokenBalance2']}
                catch{_balance = GovernanceTokenBalance}
            }
            let _name = pools[i][3]
            let _lpBalance = pools[i][4]
            console.log(_lpBalance)
            _assets.push([_ticker,_balance,_name,_lpBalance])
            _assetBalances[_ticker] = _balance
            _assetAddresses[_ticker] = pools[i][11]
        }
        
        
        let _filteredAssets =[];
        for (let i = 0; i < _assets.length; ++i) {
            console.log(_assets[i])
            if (_assets[i][3] >9999){
                _filteredAssets.push(_assets[i])
            }
            
        }
        setAssets(_assets)
        setFilteredLPAssets(_filteredAssets)
        setAssetBalances(_assetBalances)
        setAssetAddresses(_assetAddresses)
        setChooseLPTokenModalOpen(true)
        console.log(_assets)   
    };

    const closeChooseLPTokenModal = () => setChooseLPTokenModalOpen(false)

    const listLPTokens = () =>{
        if (filteredLPAssets.length === 0) {
            return (<div className="row"><div className="col p-4">You currently own no LP tokens.</div></div>)
        }

        let assetOptions = filteredLPAssets.map((element,index) =>
                <div key={index} className="assetSelectList"  role="button" onClick={()=>selectLPToken(element[0])}>
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[2]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Balance: {outputNumber(element[1],4)}</div>
                        <div className="col text-right">LP Balance: {outputNumber(Number(element[3])/(10**18),6)}</div>
                    </div>

                </div>
        );
        return(assetOptions)
    }
    
    const listAssets = () => {
        if (filteredAssets.length === 0) {
            return (<div className="row"><div className="col p-4">You currently own no assets.</div></div>)
        }
        let assetOptions = filteredAssets.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>selectAsset(element[0])}>
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[2]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Balance: {outputNumber(element[1],4)}</div>
                        <div className="col text-right">LP Balance: {element[3] > 0 ? outputNumber(Number(element[3])/(10**18),8):outputNumber(0,8)}</div>
                    </div>
                </div>
        );
        return(assetOptions)
    }
    
    const checkAllowances = async(_tokenAddress) =>{
        let tokenContract = new web3_nm.eth.Contract(ERC20_ABI,_tokenAddress)
        let _allowanceToken = await tokenContract.methods.allowance(address, MarketRouter_Address).call()
        setAllowanceToken(parseInt(_allowanceToken))
        
        let USDCContract = new web3_nm.eth.Contract(ERC20_ABI,USDC_Address)
        let _allowanceUSDC = await USDCContract.methods.allowance(address, MarketRouter_Address).call()
        setAllowanceUSDC(parseInt(_allowanceUSDC))
    }

    const checkAllowancesLP = async(_tokenAddress) =>{
        let tokenContract = new web3_nm.eth.Contract(ERC20_ABI,_tokenAddress)
        let _allowanceLPToken = await tokenContract.methods.allowance(address, MarketRouter_Address).call()
        setAllowanceLPToken(parseInt(_allowanceLPToken))
    }
    const selectAsset = async(asset) =>{
        document.getElementById('tokenAmountA').value = 0;
        document.getElementById('tokenAmountB').value = 0;
        console.log(asset)
        setSelectedAsset(asset)
        let _selectedAssetAddress = assetAddresses[asset]
        setSelectedAssetAddress(_selectedAssetAddress)
        // Get the allowance amounts
        await checkAllowances(assetAddresses[asset])
        setSelectedAssetBalance(assetBalances[asset])
        
        
        closeChooseAssetModal();
        try {
            // THIS part only when the pair exists
            let pair = await MarketFactory_nm.methods.getPair(_selectedAssetAddress,USDC_Address).call()
            console.log(pair)
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI,pair)
            let _token0 = await MarketPair.methods.token0().call();
            setToken0(_token0)

            let balanceWEI = await MarketPair.methods.balanceOf(address).call()
            var balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'))
            console.log(balance)
            setSelectedLPTokenBalance(balance)
            
            
            let reserves = await MarketPair.methods.getReserves().call()
            console.log(reserves)
            let price
            if (_token0 === USDC_Address) {
                price = parseInt(reserves[0])/parseInt(reserves[1])
            }
            else{
                price = parseInt(reserves[1])/parseInt(reserves[0])
            }
            setSelectedAssetPrice(price)
            console.log(price)
        }
        catch{
            setSelectedAssetPrice(0)
            console.log("Pair does not yet exist")
        }

        //check if the Approval button needs to be shown for token 1
        let approvalGivenToken = await checkApproval(_selectedAssetAddress, MarketRouter_Address)
        console.log(approvalGivenToken)
        if (approvalGivenToken === true) {
            setApprovalButtonTokenVisible(false)

        }
        else {
            setApprovalButtonTokenVisible(true)
        };

        //check if the Approval button needs to be shown for USDC
        let approvalGivenUSDC = await checkApproval(USDC_Address, MarketRouter_Address)
        console.log(approvalGivenUSDC)
        if (approvalGivenUSDC === true) {
            setApprovalButtonUSDCVisible(false)
        }
        else{
            setApprovalButtonUSDCVisible(true)
        };
        
        //check if the Add liquidity button is shown
        if (approvalGivenUSDC === true && approvalGivenToken === true){
            setAddLiquidityButtonVisible(true)
        }
        else {
            setAddLiquidityButtonVisible(false)
            setButtonMessage("Waiting for approval...")
            
        };
        checkButtons();
        
        
    }

    const selectAssetInitial = async(_asset, _assetAddress,pair) =>{
        setSelectedAsset(_asset)
        setSelectedAssetAddress(_assetAddress)
        // Get the allowance amounts
        await checkAllowances(_assetAddress)

        // get the balance
        let tokenContract = await new web3_nm.eth.Contract(ERC20_ABI,_assetAddress)
        let selectedAssetBalanceRaw = await tokenContract.methods.balanceOf(address).call()
        let _selectedAssetBalance = parseInt(selectedAssetBalanceRaw)/1e18
        console.log(_selectedAssetBalance)
        setSelectedAssetBalance(_selectedAssetBalance)
        
        try {
            // THIS part only when the pair exists
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI,pair)
            let _token0 = await MarketPair.methods.token0().call();
            console.log(_token0)
            setToken0(_token0)

            let balanceWEI = await MarketPair.methods.balanceOf(address).call()
            var balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'))
            console.log(balance)
            setSelectedLPTokenBalance(balance)
            console.log(selectedLPTokenBalance)
            
            let reserves = await MarketPair.methods.getReserves().call()
            console.log(reserves)
            let price
            if (_token0 === USDC_Address) {
                price = parseInt(reserves[0])/parseInt(reserves[1])
            }
            else{
                price = parseInt(reserves[1])/parseInt(reserves[0])
            }
            setSelectedAssetPrice(price)
            console.log(price)
        }
        catch{
            setSelectedAssetPrice(0)
            console.log("Pair does not yet exist")
        }

        //check if the Approval button needs to be shown for token 1
        let approvalGivenToken = await checkApproval(selectedAssetAddress, MarketRouter_Address)
        console.log(approvalGivenToken)
        if (approvalGivenToken === true) {
            setApprovalButtonTokenVisible(false)
        }
        else {
            setApprovalButtonTokenVisible(true)
        };

        //check if the Approval button needs to be shown for USDC
        let approvalGivenUSDC = await checkApproval(USDC_Address, MarketRouter_Address)
        console.log(approvalGivenUSDC)
        if (approvalGivenUSDC === true) {
            setApprovalButtonUSDCVisible(false)
        }
        else {
            setApprovalButtonUSDCVisible(true)
        };
        
        //check if the Add liquidity button is shown
        if (approvalGivenUSDC === true && approvalGivenToken === true){
            setAddLiquidityButtonVisible(true)
        }
        else {
            setAddLiquidityButtonVisible(true)
            setButtonMessage("Waiting for approval...")
        };
        checkButtons();
        
        
    }

    const selectLPToken = async(_asset) =>{
        console.log(_asset)
        document.getElementById('LPTokenAmount').value = 0
        setSelectedLPToken(_asset)
        setSelectedAsset(_asset)
        setSelectedAssetAddress(assetAddresses[_asset])
        let _selectedLPTokenAddress = assetAddresses[_asset]
        setSelectedLPTokenAddress(_selectedLPTokenAddress)
        checkAllowancesLP(assetAddresses[_asset])
        let pair = await MarketFactory_nm.methods.getPair(_selectedLPTokenAddress,USDC_Address).call()
        setSelectedLPPairAddress(pair)
        let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI,pair)
        let _token0 = await MarketPair.methods.token0().call();
        console.log(token0)
        setToken0(_token0)
        
        let balanceWEI = await MarketPair.methods.balanceOf(address).call()
        console.log(balanceWEI)
        var balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'))
        let totalSupplyWEI = await MarketPair.methods.totalSupply().call()
        let reserves = await MarketPair.methods.getReserves().call()
        console.log(reserves[0])
        console.log(totalSupplyWEI)
        let _token1Ratio =  parseInt(reserves[0]) / parseInt(totalSupplyWEI)
        let _token2Ratio = parseInt(reserves[1]) /parseInt(totalSupplyWEI)
        setToken1Ratio(_token1Ratio)
        setToken2Ratio(_token2Ratio)
        setSelectedLPTokenBalance(balance)
        setSelectedAssetBalance(assetBalances[_asset])
        
        let r = await MarketPair.methods.getReserves().call()
        let price
        if (_token0 === USDC_Address){
            price = r[0]/r[1]
        }
        else {
            price = r[1]/r[0]
        }
        setSelectedAssetPrice(price)
        //console.log(r)

        //check if the Approval button needs to be shown
        let approvalGiven = await checkApproval(pair, MarketRouter_Address)
        console.log(approvalGiven)
        if (approvalGiven === true) {
            setApprovalButtonLPTokenVisible(false)
            setRemoveLiquidityButtonVisible(true)
            
        }
        else {

            setApprovalButtonLPTokenVisible(false)
            setRemoveLiquidityButtonVisible(true)
            setButtonMessage("Waiting for approval...")

        };
        calculateTokenOutput();
        closeChooseLPTokenModal();
    };


    const selectLPInitial = async(_asset, _assetAddress,_pair) =>{
        setSelectedLPToken(_asset)
        setSelectedAsset(_asset)
        setSelectedAssetAddress(_assetAddress)
        checkAllowancesLP(_assetAddress)
        setSelectedLPPairAddress(_pair)
        let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI,_pair)
        let _token0 = await MarketPair.methods.token0().call();
        console.log(token0)
        setToken0(_token0)
        let balanceWEI = await MarketPair.methods.balanceOf(address).call()
        console.log(balanceWEI)
        var balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'))
        let totalSupplyWEI = await MarketPair.methods.totalSupply().call()
        let reserves = await MarketPair.methods.getReserves().call()
        console.log(reserves)
        let _token1Ratio = parseInt(totalSupplyWEI) / parseInt(reserves[0])
        let _token2Ratio = parseInt(totalSupplyWEI) / parseInt(reserves[1])
        setToken1Ratio(_token1Ratio)
        setToken2Ratio(_token2Ratio)
        console.log(balance)
        console.log(_token1Ratio)
    
        setSelectedLPTokenBalance(balance)
        //console.log(selectedLPTokenBalance)
        // get the balance
        let tokenContract = await new web3_nm.eth.Contract(ERC20_ABI,_assetAddress)
        let selectedAssetBalanceRaw = await tokenContract.methods.balanceOf(address).call()
        let _selectedAssetBalance = parseInt(selectedAssetBalanceRaw)/1e18
        console.log(selectedAssetBalance)        
        setSelectedAssetBalance(_selectedAssetBalance)
        console.log(_pair)
        
        let r = await MarketPair.methods.getReserves().call()
        let price
        if (token0 === USDC_Address){
            price = r[0]/r[1]
        }
        else {
            price = r[1]/r[0]
        }
        setSelectedAssetPrice(price)
        console.log(r)

        //check if the Approval button needs to be shown
        let approvalGiven = await checkApproval(_pair, MarketRouter_Address)
        console.log(approvalGiven)
        if (approvalGiven === true) {
            setApprovalButtonLPTokenVisible(false)
            setRemoveLiquidityButtonVisible(true)
        }
        else {
            setApprovalButtonLPTokenVisible(true)
            setRemoveLiquidityButtonVisible(false)
            setButtonMessage("Waiting for approval...")
        };
        calculateTokenOutput();
        closeChooseLPTokenModal();
    };

    const onSuccessApproveToken = async() =>{
        setApprovalButtonTokenVisible(false)
        await sleep(500)
        await checkAllowances(selectedAssetAddress)
        await checkAllowances(USDC_Address)
        await checkButtons()
    }

    const onSuccessApproveLP = async() =>{
        await checkAllowancesLP(selectedLPPairAddress)
        checkButtons()
    }

    const checkButtons = async() => {
        if (showAddLiquidyPart) {
            console.log(document.getElementById('tokenAmountA').value)
            if (document.getElementById('tokenAmountA').value === ''){
                return
            }
            let tokenVolume = parseFloat(document.getElementById('tokenAmountA').value)
            if (Number.isNaN(tokenVolume)){tokenVolume = 0};
            let USDVolume = parseFloat(document.getElementById('tokenAmountB').value)
            if (Number.isNaN(USDVolume)){USDVolume = 0};
            //console.log(approvalButtonTokenVisible)
            
            if (Number.isNaN(tokenVolume) === true || 
                Number.isNaN(USDVolume) === true ||
                tokenVolume === 0 ||
                USDVolume === 0){
                console.log(tokenVolume)
                setErrorButtonVisible(true)
                setButtonMessage("Enter valid amount")
            }
            else if (selectedAssetBalance < tokenVolume || USDCBalance < USDVolume) {
                
                setErrorButtonVisible(true)
                console.log(USDCBalance)
                console.log(USDVolume)
                console.log(selectedAssetBalance < tokenVolume)
                console.log(USDCBalance < USDVolume)
                setButtonMessage("Balance too low")
            }
            

            else {
                setErrorButtonVisible(false)
            };
        }
        else {
            var LPTokenAmount = parseFloat(document.getElementById('LPTokenAmount').value)
            if (Number.isNaN(LPTokenAmount)){LPTokenAmount = 0};
            //console.log(LPTokenAmount)
            //console.log(selectedLPTokenBalance)
            
            if (selectedLPTokenBalance < LPTokenAmount) {
                setErrorButtonVisible(true)
                setButtonMessage("Balance too low")
            }
            else if(Number.isNaN(LPTokenAmount) === true ||
                LPTokenAmount === 0){
                    setErrorButtonVisible(true)
                    setButtonMessage("Enter valid amount")
            }
            else {
                setErrorButtonVisible(false)
            };

        }
    }

    const calculateUSDVolume = async() =>{
        if (selectedAssetAddress === ''){
            document.getElementById('tokenAmountA').value = ''
            document.getElementById('tokenAmountB').value = ''
            return
        } // If no asset is selected
        if (document.getElementById('tokenAmountA').value === ''){
            document.getElementById('tokenAmountB').value = ''
            return
        } //If the token amount is empty
        if (document.getElementById('tokenAmountA').value === 0){return} // If the token amount is zero
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(document.getElementById('tokenAmountA').value);
        if (isPositiveNumber === false){
            if(Number.isNaN(tokenAmountA) || typeof(tokenAmountA) == 'undefined'){
                document.getElementById('tokenAmountA').value = ''
            }
            else{
                console.log(tokenAmountA)
                document.getElementById('tokenAmountA').value = tokenAmountA
            }
            return
        }

        checkAllowances(selectedAssetAddress)
        let tokenVolume = parseFloat(document.getElementById('tokenAmountA').value)
        if (tokenVolume < 0){
            tokenVolume = tokenAmountA
            document.getElementById('tokenAmountA').value = tokenVolume
        }

        let USDCVolume
        if (selectedAssetAddress === ''){
            return
        }
        console.log(selectedAssetPrice)
        if(Number.isNaN(selectedAssetPrice) !== true & selectedAssetPrice !== 0){
            setExpectedOutputVisible(true)
            if (parseInt(USDDecimals) === 6){
                USDCVolume = parseFloat(tokenVolume * parseFloat(selectedAssetPrice)*(10**12)).toFixed(6)
            }
            else{
                USDCVolume = parseFloat(tokenVolume * parseFloat(selectedAssetPrice)).toFixed(13)
            }
            
            document.getElementById('tokenAmountB').value = USDCVolume
        }
        else if( Number.isNaN(selectedAssetPrice) || selectedAssetPrice === 0){
            USDCVolume = document.getElementById('tokenAmountB').value
        }
        
        setTokenAmountA(tokenVolume)
        setTokenAmountB(USDCVolume)
        checkButtons()
        calculateAddLiquidity()

    }

    const calculateTokenVolume = async() =>{
        if (selectedAssetAddress === ''){
            document.getElementById('tokenAmountA').value = ''
            document.getElementById('tokenAmountB').value = ''
            return
        } // If no asset is selected
        if (document.getElementById('tokenAmountB').value === ''){
            document.getElementById('tokenAmountA').value = ''
            return
        } //If the token amount is empty
        if (document.getElementById('tokenAmountB').value === 0){return} // If the token amount is zero
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(document.getElementById('tokenAmountB').value);
        if (isPositiveNumber === false){
            if(Number.isNaN(tokenAmountB) || typeof(tokenAmountB) == 'undefined'){
                document.getElementById('tokenAmountB').value = ''
            }
            else{
                console.log(tokenAmountA)
                document.getElementById('tokenAmountB').value = tokenAmountB
            }
            return
        }


        
        checkAllowances(selectedAssetAddress)
        let USDCVolume = parseFloat(document.getElementById('tokenAmountB').value)
        console.log(USDCVolume)
        let TokenVolume
        if(Number.isNaN(selectedAssetPrice) !== true & selectedAssetPrice !== 0){
            TokenVolume = USDCVolume / selectedAssetPrice / (10**(18-USDDecimals))
            document.getElementById('tokenAmountA').value = TokenVolume
        }
        else if( Number.isNaN(selectedAssetPrice) || selectedAssetPrice === 0){
            TokenVolume = document.getElementById('tokenAmountA').value
        }
        else(
            console.log("DEBUG")
        )
        setTokenAmountA(TokenVolume)
        setTokenAmountB(USDCVolume)
        checkButtons()
        console.log(tokenAmountA)
        console.log(allowanceToken)
        calculateAddLiquidity()
    }
    
    const calculateTokenOutput = async() =>{
        if (selectedLPPairAddress === ''){
            document.getElementById('LPTokenAmount').value = ''
            return
        } // If no asset is selected
        if (document.getElementById('LPTokenAmount').value === ''){
            return
        } //If the token amount is empty
        if (document.getElementById('LPTokenAmount').value === 0){return} // If the token amount is zero
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(document.getElementById('LPTokenAmount').value);
        if (isPositiveNumber === false){
            if(Number.isNaN(selectedLPTokenAmount) || typeof(selectedLPTokenAmount) == 'undefined'){
                document.getElementById('LPTokenAmount').value = ''
            }
            else{
                console.log(tokenAmountA)
                document.getElementById('LPTokenAmount').value = selectedLPTokenAmount
            }
            return
        }
        
        
        checkAllowancesLP(selectedLPPairAddress)
        let LPTokenAmount = parseFloat(document.getElementById('LPTokenAmount').value)
        console.log(LPTokenAmount)
        setSelectedLPTokenAmount(LPTokenAmount)
        console.log(token0)
        console.log(USDC_Address)
        let tokenVolume
        let USDCVolume

        if (token0 === USDC_Address) {
            tokenVolume = LPTokenAmount * token2Ratio
            USDCVolume = LPTokenAmount * token1Ratio *(10**(18-USDDecimals))
        } 
        else {
            tokenVolume = LPTokenAmount * token1Ratio
            USDCVolume = LPTokenAmount * token2Ratio *(10**(18-USDDecimals))
        }
        console.log(tokenVolume)
        console.log(USDCVolume)
        await setEstimatedOutput1(tokenVolume)
        await setEstimatedOutput2(USDCVolume)
        if (Number.isNaN(USDCVolume) || Number.isNaN(tokenVolume)){
            setExpectedOutputVisible(false)
        }
        else {
            setExpectedOutputVisible(true)
        }
        checkButtons();
    }
    // Use useEffect to trigger calculateRemoveLiquidity when estimatedOutput1 or estimatedOutput2 changes
    useEffect(() => {
        if (estimatedOutput1 !== undefined && estimatedOutput2 !== undefined) {
            calculateRemoveLiquidity();
        }
    }, [estimatedOutput1, estimatedOutput2]);

    const onSuccessAddLiquidity = async () =>{
        let selectedAssetAddressOld = selectedAssetAddress

        // RESET THE VIEW    
        setSelectedAsset("Select Asset")
        setSelectedAssetAddress('')
        setSelectedAssetBalance(0)
        setSelectedAssetPrice(0)
        setErrorButtonVisible(true)
        setButtonMessage("Select an asset")

        document.getElementById('tokenAmountA').value = 0
        document.getElementById('tokenAmountB').value = 0

        // LOAD Balances etc
        await updateAssetBalanceWithAddress(selectedAssetAddressOld);
        console.log("Balance updated")
        await loadUSDBalance()
        await updatePortfolioValue()
        console.log("Portfolio value updated")
        console.log(selectedAssetAddressOld)
        await updateLPPair(selectedAssetAddressOld)
        console.log("LP pair updated")
        
    }
    
    const calculateAddLiquidity = async () =>{
        let tokenAmtA = parseFloat(document.getElementById('tokenAmountA').value).toFixed(15)
        let tokenAmtB = parseFloat(document.getElementById('tokenAmountB').value).toFixed(15)
        let amountADesiredRaw = tokenAmtA * 1e18
        let amountBDesiredRaw = tokenAmtB * (10**parseFloat(USDDecimals)-1)
        let _amountADesired = convertBigInt(amountADesiredRaw)
        let _amountBDesired = convertBigInt(amountBDesiredRaw)
        let amountAMinRaw = amountADesiredRaw * (10000 - parseInt(slippage)) / 10000
        let amountBMinRaw = amountBDesiredRaw * (10000 - parseInt(slippage)) / 10000
        let _amountAMin = convertBigInt(amountAMinRaw)
        let _amountBMin = convertBigInt(amountBMinRaw)
        setAmountADesired(_amountADesired)
        setAmountBDesired(_amountBDesired)
        setAmountAMin(_amountAMin)
        setAmountBMin(_amountBMin)
        let _deadline = Math.round(+new Date()/1000) + trxTime * 60
        setDeadline(_deadline)
    }

    const calculateRemoveLiquidity = async () =>{        
        let _liquidityToRemove = parseFloat(document.getElementById('LPTokenAmount').value) * 10**18
        console.log(_liquidityToRemove)
        setLiquidityToRemove(_liquidityToRemove)
        let amount1Raw
        let amount2Raw
        if (parseInt(USDDecimals) === 6){
            amount2Raw = roundDown(estimatedOutput2,6) * 10**6
        }
        else {
            console.log("debug")
            console.log(estimatedOutput2)
            console.log(roundDown(estimatedOutput2,6))
            amount2Raw = roundDown(estimatedOutput2,6) * 10**18
        }
        console.log(amount2Raw)
        amount1Raw = parseFloat(estimatedOutput1).toFixed(11) * 10**18
        let amount1 = web3_nm.utils.toBigInt(parseInt(amount1Raw))
        let amount2 = web3_nm.utils.toBigInt(parseInt(amount2Raw))
        console.log(amount1)
        console.log(amount2)
        let _amountAMin = parseInt(amount1 * (web3_nm.utils.toBigInt(10000-slippage)) / (web3_nm.utils.toBigInt(10000)))
        let _amountBMin = parseInt(amount2 * (web3_nm.utils.toBigInt(10000-slippage)) / (web3_nm.utils.toBigInt(10000)))
        let _deadline = Math.round(+new Date()/1000) + trxTime * 60
        
        setAmountAMin(_amountAMin)
        setAmountBMin(_amountBMin)
        setDeadline(_deadline)
    }
    const onSuccessRemoveLiquidity = async () =>{
        let selectedAssetAddressOld = selectedAssetAddress
        console.log(selectedAssetAddressOld)
  
        setSelectedLPTokenBalance(0)
        setSelectedLPToken("Select Asset")
        setSelectedLPTokenAddress('')
        setExpectedOutputVisible(false)
        setErrorButtonVisible(true)
        setButtonMessage("Select an asset")

        setSelectedLPPairAddress('')
        document.getElementById('LPTokenAmount').value = 0

        // LOAD Balances etc
        await updateAssetBalanceWithAddress(selectedAssetAddressOld);
        console.log("Balance updated")
        await loadUSDBalance()
        await updateLPPair(selectedAssetAddressOld)
        await updatePortfolioValue()
        console.log("Portfolio value updated")
        console.log("LP pair updated")
        
    }

    const showRemoveLiquidity = async() =>{
        setSelectedLPToken("Select Asset")
        setSelectedAssetAddress('')
        setExpectedOutputVisible(false)
        setSelectedLPPairAddress('')
        setErrorButtonVisible(true)
        setButtonMessage("Select an asset");
        setShowAddLiquidyPart(false);
        setShowRemoveLiquidyPart(true);
        setApprovalButtonLPTokenVisible(false);
        setStyle2("selectButtonSelected")
        setStyle1("selectButtonUnselected")
        setSelectedLPTokenBalance(0)


    }

    const showAddLiquidity = async() =>{
        setSelectedAsset("Select Asset")
        setSelectedAssetAddress('')
        setSelectedLPToken("Select Asset")
        setButtonMessage("Select an asset");
        setShowAddLiquidyPart(true);
        setShowRemoveLiquidyPart(false);
        setStyle2("selectButtonUnselected")
        setStyle1("selectButtonSelected")



    }

    const setMaxBalanceToken = async() =>{
        document.getElementById('tokenAmountA').value = roundDown(selectedAssetBalance,14).toFixed(14).replace(/\.?0+$/,"");
        calculateUSDVolume()
    }
    const setMaxBalanceUSD = async() =>{
        document.getElementById('tokenAmountB').value = roundDown(USDCBalance,6);
        calculateTokenVolume()
    }
    const setMaxBalanceLPToken = async() =>{
        document.getElementById('LPTokenAmount').value = roundDown(selectedLPTokenBalance,14).toFixed(14).replace(/\.?0+$/,"");
        calculateTokenOutput()
    }

    const setPercentOfBalanceLPToken = async(_percentage) =>{
        let _roundFactor = 14
        if(_percentage === 100){
            _roundFactor = 6 
        }
        document.getElementById('LPTokenAmount').value = roundDown(selectedLPTokenBalance*_percentage/100,_roundFactor).toFixed(_roundFactor).replace(/\.?0+$/,"");
        calculateTokenOutput()
    }

    const setPercentOfAsset = async(_percentage) =>{
        let _roundFactor = 14
        if(_percentage === 100){
            _roundFactor = 10 
        }
        document.getElementById('tokenAmountA').value = roundDown(selectedAssetBalance*_percentage/100,_roundFactor).toFixed(_roundFactor).replace(/\.?0+$/,"");
        calculateUSDVolume()
    }



    return( 
        <div className="mainContainerMobile">
            <Modal show={chooseAssetModalOpen} onHide={closeChooseAssetModal}>
                <Modal.Header className="" closeButton>
                    <Modal.Title>Select a token</Modal.Title>   
                </Modal.Header>
                <Modal.Body className="" style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                    }} 
                >
                    <div className="row p-3 pr-3 my-auto">
                        <input className="col w-100 searchField" id="search" placeholder="Search" onChange={() =>filterAssets()}></input>
                        
                    </div>
                    <div className="list-group border-nav">
                        {listAssets()}
                    </div>
                </Modal.Body>
                    
                
            </Modal>

            <Modal show={settingsModalOpen} onHide={closeSettingsModal}>
                <Modal.Header className="border" closeButton>
                    <Modal.Title>Choose your preferences</Modal.Title>   
                </Modal.Header>
                <Modal.Body className="bg-tgrey" style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                    }} 
                >
                    <div className="form-row align-items-center mx-3">
                        <div className="col">Slippage tolerance</div>
                        <div className="col col-3 bg-light">
                            <input className="px-2 form-control input-sm pull-right" type="decimals" id="slippage" onChange={saveSettingsSlippage} placeholder={slippage/100}></input>
                        </div>
                        <div className="col col-2">%</div>
                    </div>
                    {wrongSlippageInputMessage
                        ?
                        <div className="row pl-3 pt-3 w-100 text-danger ">Please input a valid slippage percentage</div>
                        :
                        ''
                    }
                    
                    {highSlippageInputMessage
                        ?
                        <div className="row pl-3 pt-3 w-100 text-danger ">Warning. Your transaction may be frontrun</div>
                        :
                        ''
                    }
                    
                    <div>&nbsp;</div>

                    <div className="form-row align-items-center mx-3">
                        <div className="col">Max transaction time</div>
                        <div className="col col-3 bg-light">
                            <input className="px-2 form-control input-sm pull-right" type="decimals" id="trxTime" onChange={saveSettingsTrxTime} placeholder={trxTime}></input>
                        </div>
                        <div className="col col-2">minutes</div>
                    </div>
                    {wrongTrxTimeInputMessage
                        ?
                        <div className="row pl-3 pt-3 w-100 text-danger ">Please input a valid transaction time</div>
                        :
                        ''
                    }                           
                </Modal.Body>
            </Modal>

            <Modal show={chooseLPTokenModalOpen} onHide={closeChooseLPTokenModal}>
                <Modal.Header className="" closeButton>
                    <Modal.Title>Select a liquidity pool</Modal.Title>   
                </Modal.Header>
                
                <Modal.Body className="" style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                    }} 
                >
                    <div className="row p-3 pr-3 my-auto">
                        <input className="col w-100 searchField" id="search" placeholder="Search" onChange={() =>filterAssets()}></input>
                        
                    </div>
                    <div className="list-group">
                        {listLPTokens()}
                    </div>
                </Modal.Body>
                
            </Modal>

            <div className="middleBoxSmallMobile">
                <div className='row m-0'>
                    <div className="mainBox">
                        <div className="selectButtonRow">
                            <div  className={style1} onClick={showAddLiquidity} role="button">
                                <b>Add liquidity</b>
                            </div>
                            <div  className={style2} onClick={showRemoveLiquidity} role="button">
                                <b>Remove liquidity</b>
                            </div>
                        </div>
                        <div className="selectSettingsRow">
                            <Gear className="h4" role="button" onClick={openSettingsModal}/>
                            
                        </div>

                            {showAddLiquidyPart 
                            ?
                                <div className="">
                                    <div className="subBox">
                                        <div className="selectAmountRow">
                                            {selectedAsset !== "Select Asset" ? <div className="percentBoxMobile" onClick={() =>setPercentOfAsset(25)} role="button" >25%</div>:''}
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            {selectedAsset !== "Select Asset" ? <div className="percentBoxMobile" onClick={() =>setPercentOfAsset(50)} role="button" >50%</div>:''}
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            {selectedAsset !== "Select Asset" ? <div className="percentBoxMobile" onClick={() =>setPercentOfAsset(75)} role="button" >75%</div>:''}
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            {selectedAsset !== "Select Asset" ? <div className="percentBoxMobile" onClick={() =>setPercentOfAsset(100)} role="button" >100%</div>:''}
                                        </div>
                                        <div className="tradingBoxRow">
                                            <div className="">
                                                <input id="tokenAmountA" onChange={() =>calculateUSDVolume()} className="inputCustomMobile" type="text" lang="en" placeholder="0.00"/>
                                            </div>
                                            <div className="">
                                            <div role="button" className="selectAssetButtonMobile" onClick={openSelectAssetModal}>
                                                <div className='assetBoxIcon'>
                                                    <img className="" src={assetIcon} alt="honey" width="24px"/>
                                                </div>
                                                <div>{selectedAsset} <KeyboardArrowDownOutlinedIcon />   </div>
                                                </div>
                                            </div>

                                        </div> 
                                        <div className='balanceRow'>
                                            <div onClick={setMaxBalanceToken} role="button" className="col align-self-end text-lg-right textBalance">
                                                {selectedAsset !== "Select Asset" ? (
                                                    <span>
                                                    Balance: {selectedAssetBalance > 0.0001 ? 
                                                        <span>{outputNumber(selectedAssetBalance, 4)}</span>
                                                     : 
                                                        <span>&lt; 0.0001</span>
                                                    }
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>      
                                    </div>
                                    <div>
                                    <div className="tradingBoxMiddleRow">
                                        <ControlPointOutlinedIcon />
                                    </div>
                                    </div>
                                    

                                    <div className="subBox">
                                        <div className="tradingBoxRow">
                                            <input id="tokenAmountB" onChange={() =>calculateTokenVolume()} className="inputCustomMobile" type="text" lang="en" placeholder="0.00"/>
                                            <div className='stablecoinAssetBox'>
                                                <div className='assetBoxIcon'>
                                                    <img className="" src={honeyIcon} alt="honey" width="24px"/>
                                                </div>
                                                {stableCoinName}
                                            </div>
                                        </div>
                                        <div className='balanceRow'>
                                            <div onClick={setMaxBalanceUSD} role="button" className="col align-self-end text-lg-right textBalance">Balance: {outputNumber(USDCBalance,2)} (Max)</div>
                                        </div>
                                    </div>
                                    <div className="row"></div>                                
                                    <div className="row">
                                        
                                            {tokenAmountA * 1e18 >= allowanceToken
                                            ?
                                            <div className="w-100 px-3 py-1">
                                                <TransactionButton
                                                    contractAddress={selectedAssetAddress} 
                                                    abi = {ERC20_ABI}
                                                    functionName= 'approve'
                                                    args =  {[MarketRouter_Address,web3_nm.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                    text="Approve Token"
                                                    onSuccess={onSuccessApproveToken}
                                                />
                                            </div>    
                                            :
                                                ''
                                            }
                                            {tokenAmountB * 1e18 >= allowanceUSDC
                                            ?
                                            <div className="w-100 px-3 py-1">
                                                <TransactionButton
                                                    contractAddress={USDC_Address} 
                                                    abi = {ERC20_ABI}
                                                    functionName= 'approve'
                                                    args =  {[MarketRouter_Address,web3_nm.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                    text={`Approve ${stableCoinName}`}
                                                    onSuccess={onSuccessApproveToken}
                                                />
                                            </div>
                                            :
                                                ''
                                            }
                                            
                                            {errorButtonVisible
                                                ?
                                                <div className="w-100 px-3 py-1">
                                                    <div className="w-100 issuaaButtonDeactivated" >{buttonMessage}</div>
                                                </div>
                                                :
                                                ''                                                        
                                            }    

                                            {tokenAmountA * 1e18 < allowanceToken &&  tokenAmountB * 1e6 < allowanceUSDC && errorButtonVisible === false
                                                ?
                                                <div className="w-100 px-3 py-1">
                                                    <TransactionButton
                                                        abi={MarketRouter_ABI} 
                                                        contractAddress={MarketRouter_Address} 
                                                        functionName="addLiquidity" 
                                                        args={[selectedAssetAddress, USDC_Address, amountADesired, amountBDesired,amountAMin,amountBMin, address, deadline] } // Example, adjust as needed
                                                        text="Add liquidity"
                                                        onSuccess = {onSuccessAddLiquidity}
                                                    />
                                                    
                                                </div>
                                                :
                                                ''
                                            }
                                            
                                        
                                    </div>
                                </div>
                                : ''
                            }
                            {showRemoveLiquidyPart 
                                ?
                                <div>
                                    <div className="subBox">
                                        <div className="selectAmountRow">
                                            {selectedLPToken !== "Select Asset" ? <div className="percentBoxMobile" onClick={() =>setPercentOfBalanceLPToken(25)} role="button" >25%</div>:''}
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            {selectedLPToken !== "Select Asset" ? <div className="percentBoxMobile" onClick={() =>setPercentOfBalanceLPToken(50)} role="button" >50%</div>:''}
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            {selectedLPToken !== "Select Asset" ? <div className="percentBoxMobile" onClick={() =>setPercentOfBalanceLPToken(75)} role="button" >75%</div>:''}
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            {selectedLPToken !== "Select Asset" ? <div className="percentBoxMobile" onClick={() =>setPercentOfBalanceLPToken(100)} role="button" >100%</div>:''}
                                            
                                        </div>
                                        <div className="tradingBoxRow">
                                            <input id="LPTokenAmount" onChange={() =>calculateTokenOutput()} className="inputCustomMobile" type="text" lang="en" placeholder="0.00"/>
                                            
                                            <div role="button" className="selectAssetButtonMobile" onClick={openChooseLPTokenModal}>
                                                <div className='assetBoxIcon'>
                                                    <img className="" src={assetIcon} alt="honey" width="24px"/>
                                                </div>
                                                <div>{selectedLPToken} <KeyboardArrowDownOutlinedIcon />  </div>
                                            </div>
                                            
                                        </div>
                                        <div className='balanceRow'>
                                            <div onClick={setMaxBalanceLPToken} role="button" className="col   align-self-end text-lg-right textBalance">
                                                {selectedLPToken !== "Select Asset" ? (
                                                    <span>
                                                    Balance: {selectedLPTokenBalance > 0.001 ? (
                                                        <span>{outputNumber(selectedLPTokenBalance, 8)}</span>
                                                    ) : (
                                                        <span>&lt; 0.001</span>
                                                    )} (Max)
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <div className="w-12">&nbsp;</div>                                      

                                        <div className="row"></div>  
                                    </div>
                                                                    
                                    <div className="row"></div>                                
                                    <div className="row">
                                        
                                        {selectedLPTokenAmount > allowanceLPToken
                                        ?
                                        <div className="w-100 px-3 py-1 pb-3">
                                            <TransactionButton
                                                contractAddress={selectedLPPairAddress} 
                                                abi = {ERC20_ABI}
                                                functionName= 'approve'
                                                args =  {[MarketRouter_Address,web3_nm.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                text="Approve"
                                                onSuccess={onSuccessApproveLP}
                                            />
                                        </div>
                                        :
                                            ''
                                        }
                                        
                                        {errorButtonVisible 
                                            ?
                                            <div className="w-100 px-3 py-1 pb-3">
                                                    <div className="issuaaButtonDeactivated" >{buttonMessage}</div>
                                            </div>
                                            :''
                                        }
                                        {errorButtonVisible === false & selectedLPTokenAmount < allowanceLPToken
                                            ?
                                            <div className="w-100 px-3 py-1 pb-3">
                                            <TransactionButton
                                                    abi={MarketRouter_ABI} // Adjust this to where you're storing ABI
                                                    contractAddress={MarketRouter_Address} 
                                                    functionName="removeLiquidity" 
                                                    args={[selectedLPTokenAddress, USDC_Address, liquidityToRemove, amountAMin,amountBMin, address, deadline] } // Example, adjust as needed
                                                    text="Remove liquidity"
                                                    onSuccess = {onSuccessRemoveLiquidity}
                                                />
                                            </div>
                                            :
                                            ''
                                        }
                                        
                                    </div>

                                    {expectedOutputVisible
                                        ?
                                        <div className="subBox">
                                            <div>
                                                Estimated Output:
                                            </div>
                                            <div>
                                                {outputNumber(estimatedOutput1,4)} {selectedLPToken} + {outputNumber(estimatedOutput2,2)} {stableCoinName}.
                                            </div>
                                        </div>
                                        :
                                            ''
                                        }  
                                </div>
                                : ''
                            }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MobilePool;