import '../../../styles/twind.css';
import React, { useState,useEffect } from 'react';
import { Modal } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import {TransactionButton} from '../../../functions/Trx';
import { useAppContext } from '../../../context/AppContext'; // Import the useAppContext hook

const MobileTWIND = () => {
    // Access context values
    const {
        web3_nm,
        GovernanceToken_nm,
        GovernanceTokenBalance,
        GovernanceToken_Address,
        ERC20_ABI,
        outputNumber,
        address,
        roundDown,
        VotingEscrow_nm,
        IDT_nm,
        lockAmount,
        lockDate,
        updateISSData,
        VotingEscrow_Address,
        VotingEscrow_ABI,
        GovernanceTokenTotalBalanceAllChains,
        GovernanceTokenStakeBalance,
        GovernanceTokenTotalBalance,
        veISSBalance,
        timeStampToDate,
        TotalVeISSSupply,
        ISSPrice,
        ISSSupplyCurrent,
        IDTBalanceWei,
        loadLimitedBlockchainData,
        updatePortfolioValue
        
    } = useAppContext();

    const [IDTModalOpen, setIDTModalOpen] = useState(false);
    const [VotingEscrowApproved, setVotingEscrowApproved] = useState(false);
    const [VotingEscrowApprovedTWIND, setVotingEscrowApprovedTWIND] = useState(false);
    const [showIDTCaluclation, setShowIDTCaluclation] = useState(false);
    
    const [lockedISSBalanceProjection, setLockedISSBalanceProjection] = useState(0);
    const [yearsToLock, setYearsToLock] = useState(false);


    const now = new Date().getTime()/1000
    
    useEffect(() => {
        checkVotingEscrowAllowance()
        checkVotingEscrowAllowanceIDT()
        loadLimitedBlockchainData()
    }, []);

        

    
    const checkVotingEscrowAllowance = async () => {
        if (typeof(VotingEscrow_nm) != 'undefined'){ 
            // check if the the allowance has been set for the VotingEscrow contract to spend TWIN
            let addressToApprove = VotingEscrow_nm._address
            let allowance = await GovernanceToken_nm.methods.allowance(address, addressToApprove).call()
            let IDTBalance = await IDT_nm.methods.balanceOf(address).call()
            if (allowance >= IDTBalance) {
                setVotingEscrowApproved(true)
          }
          else {
            setVotingEscrowApproved(false)
          } 
        }
    };
    const checkVotingEscrowAllowanceIDT = async () => {
        if (typeof(VotingEscrow_nm) != 'undefined'){ 
            // check if the the allowance has been set for the VotingEscrow contract to spend IDT
            let addressToApprove = VotingEscrow_nm._address
            let allowance = await IDT_nm.methods.allowance(address, addressToApprove).call()
            let IDTBalance = await IDT_nm.methods.balanceOf(address).call()
            if (allowance >= IDTBalance) {
                setVotingEscrowApprovedTWIND(true)
            }
            else {
                setVotingEscrowApprovedTWIND(false)
            } 
        }
    };


    const openIDTModal = () =>{
        setIDTModalOpen(true)
    }
    const closeIDTModal = () =>{
        setIDTModalOpen(false)
        setShowIDTCaluclation(false)
    }

    const calculateVeISS = () => {
        var e = document.getElementById("yearsToLock");
        var _yearsToLock = e.options[e.selectedIndex].value;
        var _lockedISSBalanceProjection = parseInt(IDTBalanceWei) * _yearsToLock / 4;
    
        // Log values to check if they are calculated correctly
        console.log('Years to Lock:', _yearsToLock);
        console.log('Locked ISS Balance Projection:', _lockedISSBalanceProjection);
    
        // Use functional state updates if necessary
        setLockedISSBalanceProjection(() => {
            console.log("Setting locked ISS balance projection:", _lockedISSBalanceProjection);
            return _lockedISSBalanceProjection;
        });
        
        setYearsToLock(() => {
            console.log("Setting years to lock:", _yearsToLock);
            return _yearsToLock;
        });
    
        setShowIDTCaluclation(true); // This should trigger a re-render
    };
    
    useEffect(() => {
        console.log(lockedISSBalanceProjection)
        if (lockedISSBalanceProjection >0){
            setShowIDTCaluclation(true)
        }
    }, [lockedISSBalanceProjection]);

    const onSuccessConvert = async() =>{
        closeIDTModal()
        updateISSData()
        //await loadLimitedBlockchainData()
        updatePortfolioValue()
    }

    const onSuccessApprove = async() =>{  
        checkVotingEscrowAllowance()
        checkVotingEscrowAllowanceIDT()
    };

    
    
        const tooltip1 = props => (
            <Tooltip {...props}>
            TWIN tokens must be staked and locked in order to participate in governance votings.
            </Tooltip>
        );

        const tooltip3 = props => (
            <Tooltip {...props}>
            Balance of liquid (i.e. not locked) TWIN available for trading, providing liquidity, a.o.
            </Tooltip>
        );
        
        
        
        return ( 
            
            <div className="container-fluid w-100">
                <Modal show={IDTModalOpen} onHide={closeIDTModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Convert your TWIND tokens into veTWIN</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text" for="yearsToLock">Number of years:</label>
                            </div>
                            <select className="form-control border" id='yearsToLock' onChange={calculateVeISS}>
                                <option vale="0" selected disabled hidden>Choose here</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        {showIDTCaluclation
                        ?
                        <div>
                            <div>
                                You will receive {outputNumber(lockedISSBalanceProjection/1e18,0)} TWIN tokens, which are locked for {yearsToLock} years. 
                                Your initial veTWIN Balance will amount to appr. {outputNumber(lockedISSBalanceProjection * yearsToLock / 4 / 1e18,0)} veTWIN.
                            </div>
                            <div className='pt-3'>This conversion is permanent and cannot be reverted. Thus please take your time and chose wisely.</div>
                        </div>
                        :
                        ''
                        }
                    </Modal.Body>
                    <Modal.Footer>
                    {showIDTCaluclation
                        ?
                        <TransactionButton
                            contractAddress={VotingEscrow_nm._address}
                            abi = {VotingEscrow_ABI}
                            functionName= 'createIDTLock'
                            args =  {[yearsToLock]} // Example, adjust as needed
                            text="Convert"
                            onSuccess={onSuccessConvert}
                        />
                        :
                        ''
                    }
                    </Modal.Footer>
                </Modal>
    
                    <div className='mainContainer'>
                        <div className="mainbox">
                           
                                
                                    <div className="subBox subBoxTWIND">
                                        
                                        <div className="TWINDFirstRow">
                                            1 TWIND token can be exchanged for up to 1 locked TWIN token. The conversion ratio depends on your lock period. 
                                        </div>
                                        <div className="TWINDLastRowWhite">
                                            If you decide to lock your tokens for the max period of 4 years, the ratio will be 1:1. if you lock for only 1 year, the conversion ratio will be 4:1.
                                        </div>
                                    </div>
                                    
                                    <div className="subBox subBoxTWIND">
                                        <div className="TWINDFirstRowGreen">
                                            <div className="">Your total TWIND balance:</div>
                                            <div className="">{outputNumber((parseInt(IDTBalanceWei)/1e18),0)}</div>                                             
                                        </div>
                                        {(IDTBalanceWei > 0 & VotingEscrowApproved === false
                                            ?
                                            <div className="TWINDButtonRow">
                                                <TransactionButton
                                                    contractAddress={GovernanceToken_nm._address}
                                                    abi = {ERC20_ABI}
                                                    functionName= 'approve'
                                                    args =  {[VotingEscrow_nm._address,web3_nm.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                    text="Approve TWIN"
                                                    onSuccess={onSuccessApprove}
                                                />
                                            </div>
                                            :
                                            ''
                                        )}
                                        {(IDTBalanceWei > 0 & VotingEscrowApprovedTWIND === false
                                            ?
                                            <div className="TWINDButtonRow">
                                                <TransactionButton
                                                    contractAddress={IDT_nm._address}
                                                    abi = {ERC20_ABI}
                                                    functionName= 'approve'
                                                    args =  {[VotingEscrow_nm._address,web3_nm.utils.toBigInt(2**255)]} // Example, adjust as needed
                                                    text="Approve TWIND"
                                                    onSuccess={onSuccessApprove}
                                                />
                                            </div>
                                            :
                                            ''
                                        )} 

                                        {(IDTBalanceWei > 0 & VotingEscrowApproved & VotingEscrowApprovedTWIND
                                            ?
                                            <div className="TWINDButtonRowLast">
                                                <div role="button" className="w-100 issuaaButton" onClick={openIDTModal}>Convert {outputNumber(parseInt(IDTBalanceWei)/1e18,0)} TWIND into veTWIN</div>
                                            </div>
                                            :
                                            ''
                                        )}
                                        {(IDTBalanceWei > 0 & (VotingEscrowApproved === false || VotingEscrowApprovedTWIND === false)
                                            ?
                                            <div className="TWINDButtonRowLast">
                                                <div disabled className="w-100 issuaaButtonDeactivated" onClick={openIDTModal}>Convert {outputNumber(parseInt(IDTBalanceWei)/1e18,0)} TWIND into veTWIN</div>
                                            </div>
                                            :
                                            ''
                                        )}         
                                    </div>
                                    

                                    <div className="subBox subBoxTWIND">
                                        <div className="TWINDFirstRowGreen">
                                            <div className="">Your total TWIN balance:</div>
                                            <div className="">{outputNumber((parseFloat(GovernanceTokenTotalBalanceAllChains)),0)}</div>                                             
                                        </div>
                                        <div className="TWINDMiddleRow">
                                            <div className="">
                                                thereof locked
                                            </div>
                                            <div className="">{outputNumber(GovernanceTokenStakeBalance,0)}</div>                                             
                                        </div>
                                        

                                        <div className="TWINDMiddleRow">
                                            <div className="">
                                                thereof liquid
                                            </div>
                                            <div className="">{outputNumber(GovernanceTokenTotalBalance - GovernanceTokenStakeBalance ,0)}</div>                                             
                                        </div>
                                    </div>
                                    
                                    <div className="subBox subBoxTWIND">
                                        <div className="TWINDFirstRowGreen">
                                            <div className="">Your veTWIN balance:</div>
                                            <div className="">{outputNumber(parseFloat(veISSBalance),0)}</div>                                             
                                        </div>
                                        <div className="TWINDMiddleRow">
                                            <div className="">
                                                Current lock ends on 
                                            </div>
                                            <div className="">
                                                {(lockDate   > now
                                                    ?
                                                    timeStampToDate(lockDate,0)
                                                    :
                                                    'n.a.'
                                                )}
                                            </div>                                             
                                        </div>
                                        <div className="TWINDLastRow">
                                            <div className="">Total veTWIN supply:</div>
                                            <div className="">{outputNumber(parseFloat(TotalVeISSSupply),0)}</div>                                             
                                        </div>
                                    </div>                                
                                
                            
                            
                        </div>
                    </div>
                    
               
            </div>

            
         );
    
}
 
export default MobileTWIND;