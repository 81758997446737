import '../../../styles/governance.css';
import React, { Component,useState,useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.css";
import {TransactionButton} from '../../../functions/Trx'; 

import { useAppContext } from '../../../context/AppContext'; // Import the useAppContext hook

const MobileGovernance = () => {
    // Access context values
    const {
        assets,
        USDCBalance,
        USDC_Address,
        MarketRouter_Address,
        location,
        web3_nm,
        outputNumber,
        address,
        MarketFactory_nm,
        USDDecimals,
        sleep,
        updateUserData,
        updateISSPrice,
        updateAssetBalanceWithAddress,
        updatePriceWithAddress,
        updatePortfolioValue,
        loadUSDBalance,
        slippage,
        trxTime,
        MarketRouter_ABI,
        MarketPair_ABI,
        ERC20_ABI,
        stableCoinName,
        assetDetails,
        GovernanceTokenBalance,
        GovernanceToken_Address,
        saveSlippagePreference,
        saveTrxTimePreference,
        DAO_nm,
        DAO_ABI,
        DAO_Address,
        GovernanceTokenStakeBalance,
        Upgrader_nm,
        Upgrader_ABI,
        Upgrader_Address,
        updateExpiryVotes,
        updateFreezeVotes,
        VoteMachine_ABI,
        VoteMachine_Address,
        timeStampToDateAndTime,
        
    } = useAppContext();
    

    // State variables
    const [view, setView] = useState('assets');
    const [styleCSS1, setStyleCSS1] = useState('governanceBoxClicked');
    const [styleCSS2, setStyleCSS2] = useState('governanceBox');
    const [styleCSS3, setStyleCSS3] = useState('governanceBox');
    const [styleCSS4, setStyleCSS4] = useState('governanceBox');
    const [freezeModalOpen, setFreezeModalOpen] = useState(false);
    const [assetToFreeze, setAssetToFreeze] = useState('');
    const [assetToVote, setAssetToVote] = useState('');
    const [voteModalOpen, setVoteModalOpen] = useState(false);
    const [assetToClose, setAssetToClose] = useState('');
    const [closeModalOpen, setCloseModalOpen] = useState(false);
    const [finalPriceModalOpen, setFinalPriceModalOpen] = useState(false);
    const [assetToVoteOnFinalPrice, setAssetToVoteOnFinalPrice] = useState('');
    const [initiateExpiryVoteModalOpen, setInitiateExpiryVoteModalOpen] = useState(false);
    const [assetToInitiateExpiryVote, setAssetToInitiateExpiryVote] = useState('');
    const [closeExpiryVoteModalOpen, setCloseExpiryVoteModalOpen] = useState(false);
    const [initiateGrantProposal, setInitiateGrantProposal] = useState(false);
    const [assetToCloseExpiryVote, setAssetToCloseExpiryVote] = useState('');
    const [voteOnGrantProposalModal, setVoteOnGrantProposalModal] = useState(false);
    const [selectedGrantVote, setSelectedGrantVote] = useState('');
    const [requestGrantButtonVisible, setRequestGrantButtonVisible] = useState(true);
    const [proposeNewAssetButtonVisible, setProposeNewAssetButtonVisible] = useState(false);
    const [initiateNewAssetProposal, setInitiateNewAssetProposal] = useState(false);
    const [proposeUgradeButtonVisible, setProposeUgradeButtonVisible] = useState(true);
    const [newAssetVotesDetailsOpen, setNewAssetVotesDetailsOpen] = useState([]);
    const [newAssetVotesDetailsClosed, setNewAssetVotesDetailsClosed] = useState([]);
    const [grantVotesDetailsOpen, setGrantVotesDetailsOpen] = useState([]);
    const [grantVotesDetailsClosed, setGrantVotesDetailsClosed] = useState([]);
    const [upgradeVotesDetailsOpen, setUpgradeVotesDetailsOpen] = useState();
    const [upgradeVotesDetailsClosed, setUpgradeVotesDetailsClosed] = useState();


    const [initiateUpgradeProposal, setInitiateUpgradeProposal] = useState();
    const [newSmartContractAddress, setNewSmartContractAddress] = useState();
    const [smartContractToUpgrade, setSmartContractToUpgrade] = useState();
    const [grantAmount, setGrantAmount] = useState();
    const [description, setDescription] = useState();
    const [proposedSymbol, setProposedSymbol] = useState();
    const [proposedName, setProposedName] = useState();
    const [proposedUpperLimit, setProposedUpperLimit] = useState();
    const [proposedDescription, setProposedDescription] = useState();
    const [selectedGrantVoteAmount, setSelectedGrantVoteAmount] = useState();
    const [selectedGrantVoteDescription, setSelectedGrantVoteDescription] = useState();
    const [voteOnUpgradeProposalModal, setVoteOnUpgradeProposalModal] = useState();
    const [selectedContractToUpgrade, setSelectedContractToUpgrade] = useState();
    const [selectedNewContractAddress, setSelectedNewContractAddress] = useState();
    const [selectedUpgradeVote, setSelectedUpgradeVote] = useState();
    const [voteOnNewAssetProposalModal, setVoteOnNewAssetProposalModal] = useState();
    const [selectedNewAssetVote, setSelectedNewAssetVote] = useState();
    const [selectedNewAssetName, setSelectedNewAssetName] = useState();
    const [expiryPriceToSubmit, setExpiryPriceToSubmit] = useState();
    const [selectedNewAssetDescription, setSelectedNewAssetDescription] = useState();
    
       

    useEffect(() => {
        if (DAO_nm !== undefined){loadNewAssetProposals();}
        if (DAO_nm !== undefined){loadGrantRequests();}
        if (DAO_nm !== undefined){loadUpgradeVotes();}
        console.log(DAO_nm)
    }, [DAO_nm]);
   

    const loadNewAssetProposals = async() =>{

        let numberOfNewAssetProposals = await DAO_nm.methods.numberOfNewAssetVotes().call();
        let newAssetsProposed = []
        console.log(numberOfNewAssetProposals)
        var _newAssetVotesDetailsOpen = [];
        console.log("Dbug")        
        var _newAssetVotesDetailsClosed = [];
        for (let i = 0; i < numberOfNewAssetProposals; ++i) {
            let proposedSymbol = await DAO_nm.methods.newAssetVoteSymbols(i).call()
            console.log(proposedSymbol)
            if (newAssetsProposed.includes(proposedSymbol)){
                continue
            }
            else {
                newAssetsProposed.push(proposedSymbol)
            }
            let result = await DAO_nm.methods.getNewAssetVotes(proposedSymbol).call()
            console.log(result)
            let hasVoted = await DAO_nm.methods.checkIfVotedNewAsset(address,proposedSymbol).call()
            console.log(hasVoted)
            if (result['open']){
                _newAssetVotesDetailsOpen.push([proposedSymbol,result['name'],result['description'],result['upperLimit'],result['endingTime'],result['yesVotes'],result['noVotes'],hasVoted])
            }
            else {
                _newAssetVotesDetailsClosed.push([proposedSymbol,result['name'],result['description'],result['upperLimit'],result['endingTime'],result['yesVotes'],result['noVotes'],hasVoted])
            }
            
            
        }
        if (parseFloat(GovernanceTokenStakeBalance) > 100000) {
            setProposeNewAssetButtonVisible(true);
            console.log("Button Visible")} 
        else {console.log(GovernanceTokenStakeBalance)};
            
        setNewAssetVotesDetailsOpen(_newAssetVotesDetailsOpen) 
        setNewAssetVotesDetailsClosed(_newAssetVotesDetailsClosed) 

    } 

    const loadGrantRequests = async() =>{
        let result = await DAO_nm.methods.getGrantVoteDetails(address).call()
        console.log(result);
        if (result[5] === true){
            setRequestGrantButtonVisible(false)
        }
        else{console.log("Button visible")};
        console.log(result)
        let numberOfGrantVotes = await DAO_nm.methods.numberOfGrantVotes().call();
        console.log(numberOfGrantVotes)
        

        var _grantVotesDetailsOpen = [];
        var _grantVotesDetailsClosed = [];
        for (let i = 0; i < numberOfGrantVotes; ++i) {
            console.log(i)
            let receivingAddress = await DAO_nm.methods.grantVoteAddresses(i).call()
            console.log(receivingAddress)
            
            let result = await DAO_nm.methods.getGrantVoteDetails(receivingAddress).call()
            console.log(result)
            let hasVoted = await DAO_nm.methods.checkIfVotedGrantFunding(address,receivingAddress).call()
            console.log(hasVoted)
            console.log(result);
            if (result[5]){
                _grantVotesDetailsOpen.push([receivingAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])

            }
            else {
                _grantVotesDetailsClosed.push([receivingAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])
            }
        }
        if (GovernanceTokenStakeBalance < 100000) {
            setRequestGrantButtonVisible(false)
        }
        else {setRequestGrantButtonVisible(true)};

        setGrantVotesDetailsOpen(_grantVotesDetailsOpen)
        setGrantVotesDetailsClosed(_grantVotesDetailsClosed)

    }

    const loadUpgradeVotes = async() =>{
        
        let result = await Upgrader_nm.methods.getUpgradeVoteDetails(address).call()
        console.log(result);
        if (result[5] === true){
            setProposeUgradeButtonVisible(false)
        }
        else{console.log("Button visible")};
        console.log(result)
        let numberOfUpgradeVotes = await Upgrader_nm.methods.numberOfUpgradeVotes().call();
        console.log(numberOfUpgradeVotes)
        

        var _upgradeVotesDetailsOpen = [];
        var _upgradeVotesDetailsClosed = [];
        for (let i = 0; i < numberOfUpgradeVotes; ++i) {
            console.log(i)
            let newContractAddress = await Upgrader_nm.methods.upgradeVoteAddresses(i).call()
            console.log(newContractAddress)
            
            let result = await Upgrader_nm.methods.getUpgradeVoteDetails(newContractAddress).call()
            console.log(result)
            let hasVoted = await Upgrader_nm.methods.checkIfVotedUpgrade(address,newContractAddress).call()
            console.log(hasVoted)
            console.log(result);
            if (result[4]){
                _upgradeVotesDetailsOpen.push([newContractAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])

            }
            else {
                _upgradeVotesDetailsClosed.push([newContractAddress,result[0],result[1],result[2],result[3],result[4],result[5],hasVoted])
            }
        }
        if (GovernanceTokenStakeBalance < 100000){
                setRequestGrantButtonVisible(false)
        }
        else {
            setRequestGrantButtonVisible(true)
        }
        setUpgradeVotesDetailsOpen()
        setUpgradeVotesDetailsClosed()
        console.log(upgradeVotesDetailsOpen) 
        console.log(upgradeVotesDetailsClosed)
        console.log(requestGrantButtonVisible)      

    }     


    const openFreezeModal=(asset)=>{
        console.log(asset)
        setAssetToFreeze(asset)
        setFreezeModalOpen(true)
    };
    
    const closeFreezeModal = () => setFreezeModalOpen(false);

    const openVoteModal=(asset)=>{
        //console.log(asset)
        setAssetToVote(asset)
        setVoteModalOpen(true)  
    };
    
    const closeVoteModal = () =>  setVoteModalOpen(false) ;
    
    const openFinalPriceModal =(asset)=>{
        setAssetToVoteOnFinalPrice(asset)
        setFinalPriceModalOpen(true)
    };
    const closeFinalPriceModal = () => setFinalPriceModalOpen(false);


    const openCloseExpiryVoteModal =(asset)=>{
        setAssetToCloseExpiryVote(asset)
        setCloseExpiryVoteModalOpen(true) 
    };
    const closeCloseExpiryVoteModal = () => setCloseExpiryVoteModalOpen(false);


    const openInitiateExpiryVoteModal =(asset)=>{
        setAssetToInitiateExpiryVote(asset)
        setInitiateExpiryVoteModalOpen(true)
    };
    const closeInitiateExpiryVoteModal = () => setInitiateExpiryVoteModalOpen(false);
    
    const openGrantPropsal = () => setInitiateGrantProposal(true);
    const closeGrantPropsal = () => setInitiateGrantProposal(false);

    const openUpgradePropsal = () => setInitiateUpgradeProposal(true);
    const closeUpgradePropsal = () => setInitiateUpgradeProposal(false);


    const onSuccessCloseExpiryVote     = async() => {
        await updateExpiryVotes()
    } 
    
    const onSuccessInitiateFreeze = async() => {
        setFreezeModalOpen(false)
        await updateFreezeVotes()
    }

    const onSuccessVoteFreeze = async() => {
        setVoteModalOpen(false)
        await updateFreezeVotes()
    }

    

    const onSuccessCloseFreezeVote     = async() => {
        await updateFreezeVotes()
    }

    const onSuccessInitiateExpiryVote = async() =>{
        setInitiateExpiryVoteModalOpen(false)
        await updateExpiryVotes()
    }

    const checkNewGrantInput = async()=>{
        let _grantAmount = parseInt(document.getElementById('grantAmount').value)*(10**18)
        let _description = document.getElementById('grantDescription').value
        setGrantAmount(_grantAmount)
        setDescription(_description)
        loadGrantRequests();
    }

    const checkNewUpgradeInput = async()=>{
        let _smartContractToUpgrade = document.getElementById('smartContractToUpgrade').value
        let _newSmartContractAddress = document.getElementById('newSmartContractAddress').value
        setSmartContractToUpgrade(_smartContractToUpgrade)
        setNewSmartContractAddress(_newSmartContractAddress)
    }

    const onSuccessNewUpgradeProposal = async()=>{
        setInitiateUpgradeProposal(false)
        loadUpgradeVotes();        
    }

    const checkExpiryPrice = async() =>{
        let _expiryPriceToSubmit = parseFloat(document.getElementById('expiryPrice').value)*1000
        setExpiryPriceToSubmit(_expiryPriceToSubmit)
    }

    const onSuccessExpiryPriceVote     = async() => {
        setFinalPriceModalOpen(false)
        await updateExpiryVotes()
    }

    const onSuccessVoteGrantProposal = async() => {
        setVoteOnGrantProposalModal(false)
        loadGrantRequests();
    }

    const onSuccessCloseGrantVote = async() =>{
        setVoteOnGrantProposalModal(false)
        loadGrantRequests();
    }

    const onSuccessVoteUpgradeProposal = async() => {
        setVoteOnUpgradeProposalModal(false)
        loadUpgradeVotes();
    }
    
    const onSuccessCloseUpgradeVote = async(newContractAddress) =>{
        loadUpgradeVotes();
    }
    
    const changeView = async (newView) =>{
       setView(newView)
        if (newView === "assets"){
            setStyleCSS1("governanceBoxClicked")
            setStyleCSS2("governanceBox")
            setStyleCSS3("governanceBox")
            setStyleCSS4("governanceBox")
        }
        if (newView === "newAsset"){
            setStyleCSS1("governanceBox")
            setStyleCSS2("governanceBoxClicked")
            setStyleCSS3("governanceBox")
            setStyleCSS4("governanceBox")
        }
        if (newView === "grantVotes"){
            setStyleCSS1("governanceBox")
            setStyleCSS2("governanceBox")
            setStyleCSS3("governanceBoxClicked")
            setStyleCSS4("governanceBox")
        }
        if (newView === "upgrade"){
            setStyleCSS1("governanceBox")
            setStyleCSS2("governanceBox")
            setStyleCSS3("governanceBox")
            setStyleCSS4("governanceBoxClicked")
        }
    }




    const closeVoteOnGrantProposalModal= () => {
        setVoteOnGrantProposalModal(false)
    };
    const openVoteOnGrantProposalModal= async(receiver) => {
        setVoteOnGrantProposalModal(true)
        setSelectedGrantVote(receiver)
        let result = await DAO_nm.methods.getGrantVoteDetails(receiver).call()
        console.log(result)
        setSelectedGrantVoteAmount(result[3])
        setSelectedGrantVoteDescription(result[4])
        
    }

    const openNewAssetPropsalModal= () => {
        setInitiateNewAssetProposal(true)
    }

    const closeNewAssetPropsalModal= () => {
        setInitiateNewAssetProposal(false)
    };

    const checkNewAssetProposalInput= async()=>{
        let _proposedName = document.getElementById('proposedName').value
        let _proposedSymbol = document.getElementById('proposedSymbol').value
        let year = new Date().getFullYear()-2000+1
        let month = new Date().getMonth()+1
        if (month<10) {month = "0".concat(month.toString())} else {month=month.toString()}
        _proposedSymbol = proposedSymbol.concat("_",year.toString(),month)
        let _proposedUpperLimit = parseInt(document.getElementById('proposedUpperLimit').value*1000).toString();
        let _proposedDescription = document.getElementById('proposedDescription').value
        setProposedName(_proposedName)
        setProposedSymbol(_proposedSymbol)
        setProposedUpperLimit(_proposedUpperLimit)
        setProposedDescription(_proposedDescription)
    
    }
    const onSuccessNewGrantProposal = async()=>{
        setInitiateGrantProposal(false)
        loadGrantRequests()
    }

    const onSuccessNewAssetProposal = async() =>{
        console.log("Debug")
    }

    const openVoteOnNewAssetProposalModal= async (symbol) => {
        let result = await DAO_nm.methods.getNewAssetVotes(symbol).call()
        console.log(result)
        setVoteOnNewAssetProposalModal(true)
        setSelectedNewAssetVote(symbol)
        setSelectedNewAssetName(result[6])
        setSelectedNewAssetDescription(result[7])
    }

    const closeVoteOnNewAssetProposalModal= () => {
        setVoteOnNewAssetProposalModal(false)
    };
    const onSuccessVoteNewAssetProposal = async() => {
        setVoteOnNewAssetProposalModal(false)
        loadNewAssetProposals();
        
    }
   
    const onSuccessCloseVoteOnNewAssetProposal = async(symbol) =>{
        loadUpgradeVotes()
    }

    const openVoteOnUpgradeProposalModal= async(newContractAddress) => {
        setVoteOnUpgradeProposalModal(true)
        setSelectedUpgradeVote(newContractAddress)
        let result = await Upgrader_nm.methods.getUpgradeVoteDetails(newContractAddress).call()

        setSelectedNewContractAddress(newContractAddress)
        setSelectedContractToUpgrade(result[3])
    }

    const closeVoteOnUpgradeProposalModal= () => {
        setVoteOnUpgradeProposalModal(false)
    };



    const tooltip1 = props => (
        <Tooltip {...props}>
        TWIN owners can initiate Expiry votings and Freeze votings for the TWIN Assets listed below.
        </Tooltip>
    );
    const tooltip2 = props => (
        <Tooltip {...props}>
        Propose new TWIN Assets. Requires min 100,000 TWIN staked.
        </Tooltip>
    );
    const tooltip3 = props => (
        <Tooltip {...props}>
        TWIN owners may propose to receive TWIN from the DAO grant reserve as reward for exeptional value-add they contributed to the TWIN
        DAO.
        </Tooltip>
    );
    const tooltip4 = props => (
        <Tooltip {...props}>
        TWIN holders can suggest and vote on upgrades of the existing smart contracts.
        </Tooltip>
    );
    const tooltip5 = props => (
        <Tooltip {...props}>
        Please select a 3-4 digit (letters only) symbol for the asset
        </Tooltip>
    );
    const tooltip6 = props => (
        <Tooltip {...props}>
        Please describe the underlying asset briefly and precisely. Please also provide the ISIN (if existing) of the underlying asset
        </Tooltip>
    );
    const tooltip7 = props => (
        <Tooltip {...props}>
        Please choose which upper limit shall apply for the short token of the asset. The upper limit should be defined at approx. twice the current market price of the underlying asset and rounded on full hundreds, thousands, etc., as applicable.
        </Tooltip>
    );
    const tooltip8 = props => (
        <Tooltip {...props}>
            Max 100,000 TWIN
        </Tooltip>
    );


       
        
        let showNewAssetProposals
        console.log(newAssetVotesDetailsOpen)
        if (typeof(newAssetVotesDetailsOpen) != 'undefined'){
            showNewAssetProposals = newAssetVotesDetailsOpen.map((details,index) =>
                <div key ={index} className="">
                    <div key ={index} className="governanceAssetBox">
                        <div id="innerBox" key ={index} className="h-100 p-4 col">
                            
                            <div className="row">
                                <div className="col-12 mb-2"><b>Active new TWIN Asset proposal vote #{index+1}</b></div>
                            </div>
                            <div className="row">
                                <div className="col-4">Symbol:</div>
                                <div className="col-8">{details[0]}</div>
                            </div>
                            <div className="row">
                                <div className="col-4">Name:</div>
                                <div className="col-8">{details[1]}</div>
                            </div>
                            
                            <div className="row">
                                <div className="col-4">Vote ends:</div>
                                <div className="col-8">{timeStampToDateAndTime(parseInt(details[4]))}</div>
                            </div>
                            <div className="row">
                                <div className="col-4">Upper Limit:</div>
                                <div className="col-8">{outputNumber(parseInt(details[3])/(1000),0)}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col"><b>Description of the Asset:</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">{details[2]}</div>
                            </div>
                            <div className="row">
                                <div className="col-4">YES votes</div>
                                <div className="col-8">
                                    {parseInt(details[5]) > 0 ?
                                        <span>{(parseInt(details[5])*100/(parseInt(details[5])+parseInt(details[6]))).toFixed(2)}</span>
                                        :
                                        <span>0</span>
                                    }
                                    %
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">NO votes</div>
                                <div className="col-8">
                                    {parseInt(details[6]) > 0 ?
                                        <span>{(parseInt(details[6])*100/(parseInt(details[5])+parseInt(details[6]))).toFixed(2)}</span>
                                        :
                                        <span>0</span>
                                    }
                                    %
                                </div>
                            </div>

                            {parseInt(details[4])*1000 > Date.now() && details[7] === false 
                                ?
                                <Button className='issuaaButton w-100' onClick={()=>openVoteOnNewAssetProposalModal(details[0])}>
                                    Vote
                                </Button>
                                :
                                null
                            }
                            {parseInt(details[4])*1000 <= Date.now() 
                                ?
                                <TransactionButton
                                    abi={DAO_ABI}
                                    contractAddress={DAO_Address}
                                    functionName="closeNewAssetVote"
                                    args={[details[0]]}
                                    text="Close Vote"
                                    onSuccess = {onSuccessNewAssetProposal}
                                /> 
                                :
                                null
                            }
                                
                        </div>
                    </div>
                </div>
            )
        }

        let showDAOvotes
        if (typeof(grantVotesDetailsOpen) != 'undefined'){
            showDAOvotes = grantVotesDetailsOpen.map((details,index) =>
                <div key ={index} className="governanceAssetBox">
                    <div key ={index} className="h-100 p-1 mb-4 col">
                        <div id="" key ={index} className="h-100 p-4 col">

                            <div className="row">
                                <div className="col-12 mb-2"><b>Active TWIN DAO grant proposal #{index+1}</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col my-auto"><b>Receiver:</b></div>
                                <div className="col addressGovernance my-auto mb-2">{details[0]}</div>
                            </div>
                            
                            <div className="row">
                                <div className="col"><b>Vote ends:</b></div>
                                <div className="col">{timeStampToDateAndTime(parseInt(details[1]))}</div>
                            </div>
                            <div className="row">
                                <div className="col"><b>Requested Amount:</b></div>
                                <div className="col">{outputNumber(parseInt(details[4])/(10**18),0)} TWIN</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col"><b>Reason for the requested DAO grant:</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">{details[5]}</div>
                            </div>
                            <div className="row">
                                <div className="col">YES votes</div>
                                <div className="col">
                                    {parseInt(details[2])>0 ?
                                        <span>(parseInt(details[2])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                        :
                                        <span>0</span>
                                    }
                                    %
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">NO votes</div>
                                <div className="col">{parseInt(details[3]) > 0 ?
                                    <span>(parseInt(details[3])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                    :
                                    <span>0</span>
                                }
                                %
                            </div>
                            </div>

                            {parseInt(details[1])*1000 > Date.now() && details[7] === false 
                                ?
                                <Button className='issuaaButton' onClick={()=>openVoteOnGrantProposalModal(details[0])}>
                                    Vote
                                </Button>
                                :
                                ''
                            }
                            {parseInt(details[1])*1000 <= Date.now() 
                                ?
                                <TransactionButton
                                    abi={DAO_ABI}
                                    contractAddress={DAO_Address}
                                    functionName="closeGrantFundingVote"
                                    args={[details[0]]}
                                    text="Close Vote"
                                    onSuccess = {onSuccessCloseGrantVote}
                                /> 
                                :
                                ''
                            }
                                
                        </div>
                    </div>
                </div>
            )
        }
        let showUpgradevotes
        if (typeof(upgradeVotesDetailsOpen) != 'undefined'){
            showUpgradevotes = upgradeVotesDetailsOpen.map((details,index) =>
            <div key ={index} className="governanceAssetBox">
                    <div key ={index} className="h-100 p-1 mb-4 col">
                        <div id="innerBox" key ={index} className="h-100 p-4 col">

                            <div className="row">
                                <div className="col-12 mb-2"><b>TWIN Code Upgrade proposal #{index+1}</b></div>
                            </div>
                            <div className="row mt-3">
                                <div className="col"><b>Contract to be upgraded:</b></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">{details[4]}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col my-auto"><b>New implementation contract address:</b></div>
                                <div className="col addressGovernance my-auto mb-2">{details[0]}</div>
                            </div>
                            
                            <div className="row">
                                <div className="col"><b>Vote ends:</b></div>
                                <div className="col">{timeStampToDateAndTime(parseInt(details[1]))}</div>
                            </div>
                            
                            
                            <div className="row">
                                <div className="col">YES votes</div>
                                <div className="col">{parseInt(details[2]>0 ? 
                                    <span>(parseInt(details[2])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                    :
                                    <span>0</span>
                                    )}
                                    %
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">NO votes</div>
                                <div className="col">{parseInt(details[3])>0 ?
                                    <span>(parseInt(details[3])*100/(parseInt(details[3])+parseInt(details[2]))).toFixed(1)</span>
                                    :
                                    <span>0</span>
                                    }
                                    %
                                </div>
                            </div>

                            {parseInt(details[1])*1000 > Date.now() && details[7] === false 
                                ?
                                <Button className='issuaaButton' onClick={()=>openVoteOnUpgradeProposalModal(details[0])}>
                                    Vote
                                </Button>
                                :
                                null
                            }
                            {parseInt(details[1])*1000 <= Date.now() 
                                ?
                                <TransactionButton
                                    abi={Upgrader_ABI}
                                    contractAddress={Upgrader_Address}
                                    functionName="closeUpgradeVote"
                                    args={[details[0]]}
                                    text="Close Vote"
                                    onSuccess = {onSuccessCloseUpgradeVote}
                                />
                                
        
                                :
                                null
                            }
                                
                        </div>
                    </div>
                </div>
            )
        }


        let assetOutput
        console.log("Asset Details:", assetDetails);
        console.log("Assets:", assets);
        if (typeof(assetDetails) != 'undefined' && typeof(assets) != 'undefined'){
            console.log(assetDetails);
            console.log(assets)
            let freezableAssets = []
            for (let i = 0; i <= assets.length-1; i++) {
                let assetSymbol = assets[i]
                console.log(assetSymbol)
                let details = assetDetails[assetSymbol]
                let frozen = details['frozen']
                if (frozen === false){freezableAssets.push(assetSymbol)}
            }
            console.log(assets);
            assetOutput = freezableAssets.map((asset,index) =>
                <div key ={index} className="governanceAssetBox">
                    <p><b>{assetDetails[asset][2]}</b></p>
                    <p>{assetDetails[asset]['description']}</p>
                    <p>Upper Limit: {(parseFloat(assetDetails[asset]['upperLimit'])/1000).toLocaleString()} USD</p>
                    <br/>
                    <b>Your position:</b>
                    <p>{asset}: {parseFloat(assetDetails[asset]['tokenBalance1']).toFixed(6)}</p>
                    <p>short{asset}: {parseFloat(assetDetails[asset]['tokenBalance2']).toFixed(6)}</p>
                    
                
                    {assetDetails[asset]['votesEndingTime'] > Date.now()/1000 && assetDetails[asset]['voteOpen'] && assetDetails[asset]['hasVoted'] === false
                        ? 
                        <div>Vote if upper limit has been breached 
                            <Button className='issuaaButton'  onClick={()=>openVoteModal(asset)}>
                                Vote
                            </Button>
                            <div>Vote closes: {timeStampToDateAndTime(parseInt(assetDetails[asset]['votesEndingTime']))}</div>
                        </div>
                        
                        :
                        null
                    }
                    {assetDetails[asset]['votesEndingTime'] < Date.now()/1000 && assetDetails[asset]['voteOpen']
                        ? 
                        
                        <TransactionButton
                            abi={VoteMachine_ABI}
                            contractAddress={VoteMachine_Address}
                            functionName="closeFreezeVote"
                            args={[asset]}
                            text="Close Vote"
                            onSuccess = {onSuccessCloseFreezeVote}
                        /> 
                        :
                        null 
                    }
                    {assetDetails[asset]['voteOpen'] !== true && assetDetails[asset]['frozen'] === false && assetDetails[asset]['expiryTime'] > Date.now()/1000  && GovernanceTokenStakeBalance >100000
                        ? 
                        <div>
                            Upper limit breached?<br />
                            <Button className='governanceButtonBlack w-100' onClick={()=>openFreezeModal(asset)}>Initiate Freeze Vote</Button>
                        </div> 
                        :
                        null
                    }

                    {assetDetails[asset]['expiryTime'] < Date.now()/1000 && 
                    assetDetails[asset]['frozen'] === false &&
                    assetDetails[asset]['hasVotedOnExpiry'] === false &&
                    assetDetails[asset]['expiryVoteEndingTime'] > Date.now()/1000  &&
                    assetDetails[asset]['expiryVoteOpen'] === true
                        ? 
                        <div>
                            <Button className='issuaaButton'  onClick={()=>openFinalPriceModal(asset)}>Vote on the expiry price</Button>
                        </div>
                        :
                        null
                    }

                    {assetDetails[asset]['expiryTime'] < Date.now()/1000 && 
                    assetDetails[asset]['frozen'] === false &&
                    assetDetails[asset]['expiryVoteOpen'] === false &&
                    parseInt(assetDetails[asset]['endOfLifeValue']) === 0
                        ? 
                        <div>
                            <Button className='issuaaButton'  onClick={()=>openInitiateExpiryVoteModal(asset)}>
                                Initiate price discovery
                            </Button>
                        </div>
                        :
                        null 
                    }


                    {assetDetails[asset]['expiryVoteEndingTime'] < Date.now()/1000 && 
                    assetDetails[asset]['expiryVoteOpen'] === true
                        ? 
                        <div>
                            <TransactionButton
                                abi={VoteMachine_ABI}
                                contractAddress={VoteMachine_Address}
                                functionName="closeEndOfLifeVote"
                                args={[asset]}
                                text="Close Vote"
                                onSuccess = {onSuccessCloseExpiryVote}
                            /> 
                            
                        </div>
                        :
                        null 
                    }
                </div>    
            );  
        } ;   

        
        
       

        return ( 
            
            <div className="mainContainer">



                
                <div className="middleBoxFull">
                    <div className="">
                        
                            <div className="mainBox">
                                <div className="governanceIntroText">
                                    Governance is currently only active on Desktop devices.
                                </div>


                            </div>

                    </div>
                </div>    
            </div>
         );
    
}

export default MobileGovernance;