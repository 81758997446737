import '../../../styles/mobilePortfolio.css';
import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext'; // Import the useAppContext hook

const MobilePortfolio = () => {
    // Access the context values
    const {
        assetDetails,
        assets,
        blockchainDataLoaded,
        USDCBalance,
        stableCoinName,
        GovernanceTokenTotalBalance,
        ISSPrice,
        MarketFactory_nm,
        web3_nm,
        MarketPair_ABI,
        USDDecimals,
        assetValue,
        LPValue,
        pools,
        outputNumber,
        totalValue,
        USDC_Address, 
        timeStampToDate
    } = useAppContext();

    // State variables
    const [portfolio, setPortfolio] = useState({});
    const [portfolioAssets, setPortfolioAssets] = useState([]);
    const [lpTokens, setLpTokens] = useState([]);
    const [expandedAsset, setExpandedAsset] = useState({});
    const [expandedLPAsset, setExpandedLPAsset] = useState({});
    


    // Function to toggle expansion
    const toggleExpansion = (asset) => {
        setExpandedAsset(prevState => ({
            ...prevState,
            [asset]: !prevState[asset]
        }));
    };

    // Function to toggle LP expansion
    const toggleLPExpansion = (asset) => {
        setExpandedLPAsset(prevState => ({
            ...prevState,
            [asset]: !prevState[asset]
        }));
    };
    // This function loads the portfolio data when the component mounts or when certain dependencies change
    useEffect(() => {
        if (blockchainDataLoaded) {
            loadPortfolio();
            loadLPData();
        }
    }, [blockchainDataLoaded, assetDetails, assets, USDCBalance, GovernanceTokenTotalBalance]);


    const loadPortfolio = async () => {
        let _portfolio = {};
        let _portfolioAssets = [];
        
        // Handle "HONEY" entry
        if (USDCBalance > 0.01) {
            _portfolio["HONEY"] = {
                name: stableCoinName,
                balance: USDCBalance,
                expiryDate: "",
                upperLimit: "",
                isFrozen: "",
                price: 1,
                portfolioValue: USDCBalance,
            };
            _portfolioAssets.push("HONEY");
        }

        // Handle "TWIN" entry
        if (GovernanceTokenTotalBalance > 0.01) {
            _portfolio["TWIN"] = {
                name: "TWIN Protocol Token",
                balance: GovernanceTokenTotalBalance,
                expiryDate: "",
                upperLimit: "",
                isFrozen: "",
                price: ISSPrice,
                portfolioValue: ISSPrice * GovernanceTokenTotalBalance,
                marketPair: '0xB526c7B7bb3ab7d8CBccdf6e6eCD2EAe9b8276bc',
            };
            _portfolioAssets.push("TWIN");
        }
        console.log(assetDetails)
        console.log(assets)
        if (assetDetails && assets && USDC_Address && MarketFactory_nm) {
            for (let i = 0; i < assets.length; ++i) {
                const asset = assets[i];
                console.log(asset)
                // Long assets
                if (parseFloat(assetDetails[asset]?.tokenBalance1) > 0.0000001) {
                    const tokenAddress = assetDetails[asset][0];
                    const pair = await MarketFactory_nm.methods.getPair(tokenAddress, USDC_Address).call();
                    const MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
                    const reserves = await MarketPair.methods.getReserves().call();
                    const token0 = await MarketPair.methods.token0().call();
                    const tokenPrice = token0 === USDC_Address
                        ? Number(reserves[0]) * (10 ** (18 - USDDecimals)) / Number(reserves[1])
                        : Number(reserves[1]) / Number(reserves[0]) * (10 ** (18 - USDDecimals));
                    
                    _portfolio[asset] = {
                        name: assetDetails[asset].name,
                        balance: assetDetails[asset].tokenBalance1,
                        expiryDate: assetDetails[asset].expiryTime,
                        upperLimit: assetDetails[asset].upperLimit,
                        isFrozen: assetDetails[asset].frozen,
                        price: tokenPrice,
                        portfolioValue: parseFloat(assetDetails[asset].tokenBalance1) * tokenPrice,
                        marketPair: pair,
                    };
                    _portfolioAssets.push(asset);
                }

                // Inverse assets
                if (parseFloat(assetDetails[asset]?.tokenBalance2) > 0.0000001) {
                    const tokenAddress = assetDetails[asset][1];
                    const pair = await MarketFactory_nm.methods.getPair(tokenAddress, USDC_Address).call();
                    const MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
                    const reserves = await MarketPair.methods.getReserves().call();
                    const token0 = await MarketPair.methods.token0().call();
                    const tokenPrice1 = token0 === USDC_Address
                        ? Number(reserves[0]) * (10 ** (18 - USDDecimals)) / Number(reserves[1])
                        : Number(reserves[1]) / Number(reserves[0]) * (10 ** (18 - USDDecimals));
                    
                    _portfolio["i" + asset] = {
                        name: "Inverse " + assetDetails[asset].name,
                        balance: assetDetails[asset].tokenBalance2,
                        expiryDate: assetDetails[asset].expiryTime,
                        upperLimit: assetDetails[asset].upperLimit,
                        isFrozen: assetDetails[asset].frozen,
                        price: tokenPrice1,
                        portfolioValue: parseFloat(assetDetails[asset].tokenBalance2) * tokenPrice1,
                        marketPair: pair,
                    };
                    _portfolioAssets.push("i" + asset);
                }
            }
        }
        
        setPortfolio(_portfolio);
        setPortfolioAssets(_portfolioAssets);
    };

        // Load LP Data
        const loadLPData = () => {
            let _lpTokens = [];
    
            if (pools && pools.length > 0) {
                for (let i = 0; i < pools.length; i++) {
                    const pool = pools[i];
                    const share = (parseFloat(pool[4]) / parseFloat(pool[5])) * 100;
    
                    if (share > 0.001) {
                        _lpTokens.push({
                            symbol: pool[0],
                            name: pool[3],
                            lpBalance: outputNumber(parseFloat(pool[4]) / 1e18, 8),
                            withdrawableAssets: `${outputNumber((parseFloat(pool[4]) / parseFloat(pool[5])) * pool[8] / 1e18, 3)} ${pool[0]} & ${outputNumber((parseFloat(pool[4]) / parseFloat(pool[5]) * pool[2] * 0.5) , 1)} ${stableCoinName}`,
                            poolShare: `${share.toFixed(2)}%`,
                            value: outputNumber((parseFloat(pool[4]) / parseFloat(pool[5]) * parseFloat(pool[2])), 0),
                        });
                    }
                }
            }
    
            setLpTokens(_lpTokens);
            console.log(_lpTokens)
        };

    // The rest of the render logic remains the same
    return (
        <div className="mainContainerMobile">
            <div className="middleBoxFullMobile"> 
                
                <div className="portfolioTotalValueBoxMobile">
                    <div className='portfolioBoxMobileLeft'>
                        <div className="portfolioBoxItemMobile">$ {totalValue > 0 ?
                            outputNumber(totalValue, 0)
                            :
                            <span>0</span>                            }
                        </div>
                        <div className='portfolioBoxItem2Mobile'>Total portfolio value</div>
                        
                    </div>
                    <div className='infoBoxIconMobile'><AttachMoneyOutlinedIcon fontSize='inherit' /></div>
                        
                </div>

                <div className='portfolioAssetBoxMobile'>
                    <div className="portfolioBoxHeaderMobile">
                        <div className="">Your Assets</div>
                    </div>
                    <div className="row mainPortfolioContentMobile">    
                        <div className="pl-3 pr-3  w-100">
                            {/* Example render output for your assets */}
                            {portfolioAssets && portfolioAssets.length > 0 ? (
                                <div className="w-100">
                                    {portfolioAssets.map((asset, index) => (
                                        <div className = 'portfolioCard' key={index}>
                                            <div className='portfolioCardHeader'>
                                                <div className="text-left bold">{portfolio[asset]?.name} ({asset})</div> {portfolio[asset]?.isFrozen ? <div className='portfolioStatusFrozenMobile'>FROZEN</div> : <div className='portfolioStatusLiveMobile'>LIVE</div>}
                                            </div>
                                            <div className="text-left">Current Price: ${outputNumber(portfolio[asset]?.price, 2)}</div>
                                            <div className="text-left">Your holding: {outputNumber(portfolio[asset]?.balance, 4)}</div>
                                            <div className="text-left">Position value: ${outputNumber(portfolio[asset]?.portfolioValue, 0)}</div>

                                            {/* Expandable section */}
                                            {expandedAsset[asset] && (
                                                <>
                                                    <div className="text-left">
                                                        {asset !== "TWIN" && asset !== "HONEY" 
                                                            ? <div>Upper limit: ${outputNumber((parseFloat(portfolio[asset]?.upperLimit) / 1000), 2)}</div> 
                                                            : <div>n.a.</div>
                                                        }
                                                    </div>
                                                    <div className="text-left">
                                                        {asset !== "TWIN" && asset !== "HONEY" 
                                                            ? <div>Expiry date: {timeStampToDate(portfolio[asset]?.expiryDate)}</div> 
                                                                
                                                            : 'n.a.'}
                                                    </div>
                                                </>
                                            )}
                                            <div 
                                                className="expandToggle"
                                                    onClick={() => toggleExpansion(asset)}
                                                    style={{ cursor: 'pointer', textDecoration: 'underline', textAlign:'right' }}
                                            >
                                                {expandedAsset[asset] ? "Collapse" : "Expand"}
                                            </div>
                                        <div>
                                                {portfolio[asset]['name'] !== stableCoinName ?
                                                    <div className="d-flex flex-row tradeButtonRowMobile">
                                                        <Link to={`/trade/buy/${portfolio[asset]['marketPair']}`}>
                                                            <div className="buyButtonMobile">Buy</div>
                                                        </Link>
                                                        <Link to={`/trade/sell/${portfolio[asset]['marketPair']}`}>
                                                            <div className="sellButtonMobile">Sell</div>
                                                        </Link>
                                                    </div>
                                                :''}
                                            </div>
                                        </div>
                                    ))}
                                    
                                </div>
                            ) : (
                                <div className='portfolioCard'>
                                    <div>No assets available</div>
                                </div>
                            )}
                            <div className='portfolioCardSummary'>
                                <div className="text-left portfolioCardHeader">Total Assets: $ {outputNumber(assetValue,0)}</div>
                            </div>
                        </div>
                    </div>   
                </div>

                <div className='portfolioAssetBoxMobile'>           
                    <div className="portfolioBoxHeaderMobile">
                        <div className="">Your liquidity provider tokens</div>
                    </div>
                    <div className="row mainPortfolioContentMobile">    
                        <div className="pl-3 pr-3  w-100">
                            
                                
                                
                                {lpTokens.length > 0 ? (
                                    lpTokens.map((lp, index) => (
                                        <div className='portfolioCard' key={index}>
                                            <div className='portfolioCardHeader'>
                                                <div className="text-left bold">{lp.name} ({lp.symbol})</div>
                                            </div>
                                            <div className="text-left">Position value: ${lp.value}</div>
                                            {/* Expandable section */}
                                            {expandedLPAsset[index] && (
                                                    <>
                                                        <div className="text-left">
                                                            <div className="text-left">Your LP Balance: {lp.lpBalance}</div>
                                                            <div className="text-left">Widthdrawable assets:{lp.withdrawableAssets}</div>
                                                            <div className="text-left">Your pool share: {lp.poolShare}</div>
                                                        </div>
                                                    </>
                                                )}
                                                <div 
                                                    className="expandToggle"
                                                        onClick={() => toggleLPExpansion(index)}
                                                        style={{ cursor: 'pointer', textDecoration: 'underline', textAlign:'right' }}
                                                >
                                                    {expandedLPAsset[index] ? "Collapse" : "Expand"}
                                                </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='portfolioCard'>
                                        <div>No LP tokens available</div>
                                    </div>
                                )}
                                <div className='portfolioCardSummary'>
                                    <div className="text-left portfolioCardHeader">Total LP tokens: $ {outputNumber(LPValue,0)}</div>
                                </div>
                                
                                
                            
                        </div>  
                    </div>                                    
                </div>
                
            </div>
        </div>
    );
};

export default MobilePortfolio;
