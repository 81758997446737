import '../../../styles/mobileFooter.css';
import {Component} from 'react';
import discord from '../../../img/icons/DiscordIconGrey.png';
import twitter from '../../../img/icons/XIconGrey.png';
import telegram from '../../../img/icons/TelegramIconGrey.png';
import { Modal} from "react-bootstrap";
class Footer extends Component{







  
  constructor(props) {
    super(props);
    this.state = {  
      account: '',
      loading: true,
      disclaimerModalOpen: false,
    }
  }

  changePagePortfolio = () => {
      console.log("Changing view to portfolio")
  }
  closeDisclaimerModal = () => {
      this.setState({disclaimerModalOpen: false})
  }
  
  openDisclaimerModal = () => {
      this.setState({disclaimerModalOpen: true})
  }


  render(){
    return (
      <div className="footer">
          <Modal show={this.state.disclaimerModalOpen} onHide={this.closeDisclaimerModal}>
            <Modal.Header className="border" closeButton>
                <Modal.Title>Legal notice</Modal.Title> 
            
            </Modal.Header>
            
            <Modal.Body className="bg-tgrey" style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
             }} 
            >
                <div>
                  This website provides information and content of general nature about ISSUAA as a decentralized finance protocol (ISSUAA protocol) and as a decentralized autonomous organization (ISSUAA DAO). 
                    
                </div>
                <div>
                  The information and the content of this website may be wrong, subject to change or incomplete and may not be updated. You should not rely on this website or on any of the content therein for any kind of investment decision or for legal advice, financial advice, investment advice, or any kind of advice. 
                    
                </div>
                <div>
                  You always act at your own risk with respect to the content of this website and the ISSUAA protocol. In no way are the owners of, or contributors to the ISSUAA protocol, the ISSUAA DAO and to this website responsible for the actions, decisions, or other behavior taken or not taken by you in connection with this website or your potential interaction with the ISSUAA protocol. 
                    
                </div>
                <div>
                  Please note that the ISSUAA protocol is operated by the ISSUAA DAO and you are solely responsible for compliance with all laws that may apply to you and your use of the ISSUAA protocol and this website. 
                </div>
                <div>
                  Cryptocurrencies and blockchain technologies have been the subject of scrutiny by various regulatory bodies across the globe. 
                </div>
                <div>
                  ISSUAA DAO and its contributors make no representation regarding the application to your use of the ISSUAA protocol or this website of any laws, including, without limitation, those related to investments, tax, gaming, options, derivatives, or securities. Depending on the jurisdiction, the use of the ISSUAA protocol may be restricted. 
                  
                </div>
                <div>
                  You agree that ISSUAA DAO and its contributors are not responsible for determining whether or which laws may apply to you and your use of the ISSUAA protocol and this website. 
                </div>
                <div>
                  The ISSUAA DAO may restrict the use of the ISSUAA protocol to citizens and residents of certain jurisdictions. Particular restrictions apply for US residents who are strictly prohibited from purchasing, minting, farming or staking crypto assets using the ISSUAA protocol. 
                </div>
                <div>
                  Further, restricted persons should not interact with the ISSUAA protocol at any time. By interacting with any smart contract of the ISSUAA protocol you expressly and unconditionally affirm that you are not a resident of the US. 
                </div>
                <div>
                  All rights reserved. No guarantees, warranties or whatsoever given. <b>DeFi tools are not toys. Do your own research.</b>
                </div>

              </Modal.Body>
                        
          </Modal>


          <div className="footerMobile">
              
                
                <div className="footerSocials">
                  <a href="https://t.me/twin_finance" target="_blank" rel="noreferrer"><img src={telegram} alt="discord" height="16" className="socials"/></a>
                  &nbsp;&nbsp;&nbsp;
                  <a href="https://discord.gg/9ZwbkdjmtJ" target="_blank" rel="noreferrer"><img src={discord} alt="discord" height="16" className="socials"/></a>
                  &nbsp;&nbsp;&nbsp;
                  <a href="https://twitter.com/TwinFinance" target="_blank" rel="noreferrer"><img src={twitter} alt="twitter" height="16" className="socials"/></a>
                </div>
                <div className="footerCopyright">
                  &copy; Copyright 2024 TWIN Finance | All Rights Reserved
                </div>
              </div>
           

              
           
          
      </div>
    )
  } 
}

export default Footer;
