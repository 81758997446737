// MenuItem.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
const MenuItem = ({ to, icon: Icon, label }) => (
  <Nav.Link>
    <Link to={to}>
      <div className="menuItemMobile" type="button">
        <div className="" style={{}}>
          <Icon />
          &nbsp;&nbsp;{label}
        </div>
      </div>
    </Link>
  </Nav.Link>
);

export default MenuItem;
