import { useState, useEffect } from 'react';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from "wagmi";

export default function MobileConnectButton() {
  const { open } = useWeb3Modal();
  const account = useAccount();
  const address = account.address;
  const [isConnecting, setIsConnecting] = useState(false);

  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 4)}...${address.substring(address.length - 5)}`;
  };

  const addressShort = shortenAddress(address);

  const handleConnect = async () => {
    setIsConnecting(true);
    await open();
    // Keep isConnecting true until a connection is detected
  };

  // Use useEffect to monitor when the address becomes available
  useEffect(() => {
    if (address) {
      setIsConnecting(false); // Stop showing "Connecting..." once connected
    }
  }, [address]);
  // Use useEffect to monitor when the address becomes available

  useEffect(() => {
    if (address) {
      setIsConnecting(false); // Stop showing "Connecting..." once connected
    }
  }, [isConnecting]);

  return (
    <div className='connectButtonMobile'>  
      <div onClick={handleConnect}>
        {isConnecting ? (
          <span className=''>
            <div className="spinner-border spinner-border-sm spinner-border-slow" role="status" />
            Connecting...
          </span>
        ) : address ? (
          <span>{addressShort}</span>
        ) : (
          <span>Connect Wallet</span>
        )}
      </div>
    </div>
  );
}
