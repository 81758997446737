import '../../../styles/mobileMainpage.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext'; // Import useAppContext

import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

const Mainpage = () => {
    // Access the context values
    const {
        activePage,
        assets,
        pools,
        outputNumber,
        ISSPrice,
        totalLockedValue,
        ISSMarketCapCurrent,
        farmActive
    } = useAppContext();

    const [poolOutput, setPoolOutput] = useState('');

    useEffect(() => {
        if (typeof pools !== 'undefined') {
            setPoolOutput(pools.map((pool, index) => (
                <div className='assetItemMobile' key={index}>
                    <div className="firstRow bold">{pool[3]} ({pool[0]})</div>
                    
                    <div className="text-left">Price: {outputNumber(pool[10], 2)}$</div>
                    <div className="text-left">
                        {isNaN(outputNumber(parseFloat(pool[9]) / 1000, 0)) ? '' : 
                            <div>
                            Upper limit: {outputNumber(parseFloat(pool[9]) / 1000, 0)}$
                            </div>
                        }
                    </div>
                    <div className="text-left">Pool liquidity: {outputNumber(pool[2] , 0)}$</div>
                    <div>
                        <div className="d-flex flex-row tradeButtonRowMobile">
                            <Link to={`/trade/buy/${pool[1]}`}>
                                <div className="buyButtonMobile">Buy</div>
                            </Link>
                            <Link to={`/trade/sell/${pool[1]}`}>
                                <div className="sellButtonMobile">Sell</div>
                            </Link>
                        </div>
                    </div>
                   
                </div>
            )));
        }
    }, [pools, outputNumber, farmActive]);

    return (
        <div className="mainContainer">
            <div className="middleBoxFullMobile">
            
                <div className="">
            
                    <div className="infoBoxesMobile">
                        <div className="infoBoxMobile">
                            <div className='infoBoxMobileLeft'>
                                <div className="infoBoxItemMobile">$ {outputNumber(parseFloat(ISSPrice), 3) > 0 ?
                                    outputNumber(parseFloat(ISSPrice), 3)
                                    :
                                    <span>...</span>
                                }
                                </div>
                                <div className='infoBoxItem2Mobile'>TWIN Price</div>
                                
                            </div>
                            <div className='infoBoxIconMobile'><AttachMoneyOutlinedIcon fontSize='inherit' /></div>
                                
                        </div>

                        <div className="infoBoxMobile">
                            <div>
                                <div className="infoBoxItemMobile">{typeof assets !== 'undefined' ? assets.length * 2 + 1 : <span>...</span>}</div>
                                <div className="infoBoxItem2Mobile">Assets</div>
                            </div>
                            <div className='infoBoxIconMobile'><WorkOutlineOutlinedIcon fontSize='inherit' /></div>
                            
                        </div>
                        <div className="infoBoxMobile">
                            <div className='infoBoxMobileLeft'>
                                <div className="infoBoxItemMobile">$ {typeof totalLockedValue !== 'undefined' ? outputNumber(totalLockedValue / 1e6, 2) : <span>...</span>}m</div>
                                <div className="infoBoxItem2Mobile">Value locked</div>
                            </div>

                            <div className='infoBoxIconMobile'><AttachMoneyOutlinedIcon fontSize='inherit' /></div>
                        </div>
                        <div className="infoBoxMobile">
                            <div className='infoBoxMobileLeft'>
                                <div className="infoBoxItemMobile">$ {typeof assets !== 'undefined' ? outputNumber(ISSMarketCapCurrent / 1e6, 2) : <span>...</span>}m</div>
                                <div className="infoBoxItem2Mobile">TWIN Market Cap</div>
                            </div>
                            <div className='infoBoxIconMobile'><PieChartRoundedIcon fontSize='inherit' /></div>
                        </div>
                    </div>

                    <div className="mainpageDashboardMobile">
                        <div className="">
                            <div className="mainpageHeadline">TWIN Assets and pools</div>
                            <br />
                            <div className="">
                                {poolOutput}
                                <div className="buffer"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mainpage;
