import axios from 'axios';

export const checkIn = async (_address) => {
  const url = 'https://api.twinfinance.io:9443/checkIn'
  const data = {
    'address':_address
  }
  
  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json', // This is important for sending JSON data
      },
    });

    console.log('Response:', response.data);
    return ({'success':true, 'data':response.data})
  } catch (error) {
    console.error('Error:', error);
    return({'success':false, 'data':error})
  }
};
