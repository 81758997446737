import React, { useEffect, useState } from 'react';
import { format } from "d3-format";
import { scaleTime } from "d3-scale";
import { timeFormat } from "d3-time-format";
import {
  Chart,
  ChartCanvas,
  CandlestickSeries,
  XAxis,
  YAxis,
  CrossHairCursor,
  MouseCoordinateX,
  MouseCoordinateY,
  EdgeIndicator,
  ZoomButtons,
} from "react-financial-charts";

const MobileCandlestickChart = ({ symbol, height }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetCount, setResetCount] = useState(0);
  const [width, setWidth] = useState(Math.min(window.innerWidth * 0.8, 550)); // 80% of screen width, capped at 600px

  useEffect(() => {
    const handleResize = () => {
      setWidth(Math.min(window.innerWidth * 0.8, 600)); // Update width with max of 600px
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://api.twinfinance.io:9443/candlestick?symbol=${symbol}`);
        const result = await response.json();
        const formattedData = result.map(d => ({
          date: new Date(d.date),
          open: d.open,
          high: d.high,
          low: d.low,
          close: d.close,
          volume: d.volume,
        }));
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [symbol]);

  if (symbol === 'Select Asset') return null;
  if (loading || data.length === 0) {
    return (
      <div className="mt-3">
        <div className="middleBoxSmall">
          <div className="mainBox"> 
            Loading chart data...
          </div>
        </div>
      </div>
    );
  }

  const xScale = scaleTime()
    .domain([data[0].date, data[data.length - 1].date])
    .range([0, width]);

  const xAccessor = d => d.date;
  const displayXAccessor = xAccessor;
  const pricesDisplayFormat = format(".2f");
  const timeDisplayFormat = timeFormat("%d %b");

  const yExtentsCalculator = ({ plotData }) => {
    let minLow = Infinity;
    let maxHigh = -Infinity;

    plotData.forEach(({ low, high }) => {
      if (low !== undefined && low < minLow) minLow = low;
      if (high !== undefined && high > maxHigh) maxHigh = high;
    });

    const padding = (maxHigh - minLow) * 0.05;
    return [minLow, maxHigh + padding];
  };

  return (
    <div className="mt-3">
      <div className="middleBoxSmall">
        <div className="mainBox">   
          <ChartCanvas
            height={height}
            width={width}
            ratio={3}
            margin={{ left: 0, right: 48, top: 0, bottom: 24 }}
            seriesName={`Chart ${resetCount}`}
            data={data}
            xScale={xScale}
            xAccessor={xAccessor}
            displayXAccessor={displayXAccessor}
            xExtents={[xAccessor(data[0]), xAccessor(data[data.length - 1])]}
          >
            <Chart id={1} yExtentsCalculator={yExtentsCalculator}>
              <XAxis
                showGridLines
                scale={xScale}
                tickFormat={timeDisplayFormat}
                ticks={6}
              />
              <YAxis showGridLines tickFormat={pricesDisplayFormat} />
              <CandlestickSeries />
              
              <MouseCoordinateX
                at="bottom"
                orient="bottom"
                displayFormat={timeDisplayFormat}
              />
              <MouseCoordinateY displayFormat={pricesDisplayFormat} />

              <EdgeIndicator
                itemType="last"
                rectWidth={48}
                fill={d => (d.close > d.open ? "#26a69a" : "#ef5350")}
                lineStroke={d => (d.close > d.open ? "#26a69a" : "#ef5350")}
                displayFormat={pricesDisplayFormat}
                yAccessor={d => d.close}
              />

              <ZoomButtons
                onReset={() => setResetCount(resetCount + 1)}
              />
              <CrossHairCursor />
            </Chart>
          </ChartCanvas>
        </div>
      </div>
    </div>
  );
};

export default MobileCandlestickChart;
