import '../../../styles/portfolio.css';
import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext'; // Import the useAppContext hook

const Portfolio = () => {
    // Access the context values
    const {
        assetDetails,
        assets,
        blockchainDataLoaded,
        USDCBalance,
        stableCoinName,
        GovernanceTokenTotalBalance,
        ISSPrice,
        MarketFactory_nm,
        web3_nm,
        MarketPair_ABI,
        USDDecimals,
        assetValue,
        LPValue,
        pools,
        outputNumber,
        totalValue,
        USDC_Address, 
        timeStampToDate
    } = useAppContext();

    // State variables
    const [portfolio, setPortfolio] = useState({});
    const [portfolioAssets, setPortfolioAssets] = useState([]);
    const [lpTokens, setLpTokens] = useState([]);

    
    // This function loads the portfolio data when the component mounts or when certain dependencies change
    useEffect(() => {
        if (blockchainDataLoaded) {
            loadPortfolio();
            loadLPData();
        }
    }, [blockchainDataLoaded, assetDetails, assets, USDCBalance, GovernanceTokenTotalBalance]);


    const loadPortfolio = async () => {
        let _portfolio = {};
        let _portfolioAssets = [];
        
        // Handle "HONEY" entry
        if (USDCBalance > 0.01) {
            _portfolio["HONEY"] = {
                name: stableCoinName,
                balance: USDCBalance,
                expiryDate: "",
                upperLimit: "",
                isFrozen: "",
                price: 1,
                portfolioValue: USDCBalance,
            };
            _portfolioAssets.push("HONEY");
        }

        // Handle "TWIN" entry
        if (GovernanceTokenTotalBalance > 0.01) {
            _portfolio["TWIN"] = {
                name: "TWIN Protocol Token",
                balance: GovernanceTokenTotalBalance,
                expiryDate: "",
                upperLimit: "",
                isFrozen: "",
                price: ISSPrice,
                portfolioValue: ISSPrice * GovernanceTokenTotalBalance,
                marketPair: '0xB526c7B7bb3ab7d8CBccdf6e6eCD2EAe9b8276bc',
            };
            _portfolioAssets.push("TWIN");
        }
        console.log(assetDetails)
        console.log(assets)
        if (assetDetails && assets && USDC_Address && MarketFactory_nm) {
            for (let i = 0; i < assets.length; ++i) {
                const asset = assets[i];
                console.log(asset)
                // Long assets
                if (parseFloat(assetDetails[asset]?.tokenBalance1) > 0.0000001) {
                    const tokenAddress = assetDetails[asset][0];
                    const pair = await MarketFactory_nm.methods.getPair(tokenAddress, USDC_Address).call();
                    const MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
                    const reserves = await MarketPair.methods.getReserves().call();
                    const token0 = await MarketPair.methods.token0().call();
                    const tokenPrice = token0 === USDC_Address
                        ? Number(reserves[0]) * (10 ** (18 - USDDecimals)) / Number(reserves[1])
                        : Number(reserves[1]) / Number(reserves[0]) * (10 ** (18 - USDDecimals));
                    
                    _portfolio[asset] = {
                        name: assetDetails[asset].name,
                        balance: assetDetails[asset].tokenBalance1,
                        expiryDate: assetDetails[asset].expiryTime,
                        upperLimit: assetDetails[asset].upperLimit,
                        isFrozen: assetDetails[asset].frozen,
                        price: tokenPrice,
                        portfolioValue: parseFloat(assetDetails[asset].tokenBalance1) * tokenPrice,
                        marketPair: pair,
                    };
                    _portfolioAssets.push(asset);
                }

                // Inverse assets
                if (parseFloat(assetDetails[asset]?.tokenBalance2) > 0.0000001) {
                    const tokenAddress = assetDetails[asset][1];
                    const pair = await MarketFactory_nm.methods.getPair(tokenAddress, USDC_Address).call();
                    const MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
                    const reserves = await MarketPair.methods.getReserves().call();
                    const token0 = await MarketPair.methods.token0().call();
                    const tokenPrice1 = token0 === USDC_Address
                        ? Number(reserves[0]) * (10 ** (18 - USDDecimals)) / Number(reserves[1])
                        : Number(reserves[1]) / Number(reserves[0]) * (10 ** (18 - USDDecimals));
                    
                    _portfolio["i" + asset] = {
                        name: "Inverse " + assetDetails[asset].name,
                        balance: assetDetails[asset].tokenBalance2,
                        expiryDate: assetDetails[asset].expiryTime,
                        upperLimit: assetDetails[asset].upperLimit,
                        isFrozen: assetDetails[asset].frozen,
                        price: tokenPrice1,
                        portfolioValue: parseFloat(assetDetails[asset].tokenBalance2) * tokenPrice1,
                        marketPair: pair,
                    };
                    _portfolioAssets.push("i" + asset);
                }
            }
        }
        
        setPortfolio(_portfolio);
        setPortfolioAssets(_portfolioAssets);
    };

        // Load LP Data
        const loadLPData = () => {
            let _lpTokens = [];
    
            if (pools && pools.length > 0) {
                for (let i = 0; i < pools.length; i++) {
                    const pool = pools[i];
                    const share = (parseFloat(pool[4]) / parseFloat(pool[5])) * 100;
    
                    if (share > 0.01) {
                        _lpTokens.push({
                            symbol: pool[0],
                            name: pool[3],
                            lpBalance: outputNumber(parseFloat(pool[4]) / 1e18, 8),
                            withdrawableAssets: `${outputNumber((parseFloat(pool[4]) / parseFloat(pool[5])) * pool[8] / 1e18, 3)} ${pool[0]} & ${outputNumber((parseFloat(pool[4]) / parseFloat(pool[5]) * pool[2] * 0.5) , 1)} ${stableCoinName}`,
                            poolShare: `${share.toFixed(2)}%`,
                            value: outputNumber((parseFloat(pool[4]) / parseFloat(pool[5]) * parseFloat(pool[2])), 0),
                        });
                    }
                }
            }
    
            setLpTokens(_lpTokens);
        };

    // The rest of the render logic remains the same
    return (
        <div className="mainContainer">
            <div className="middleBoxFull"> 
                <div className="contentContainer">
                    <div className="portfolioTotalValueBox">
                        <div className='infoBoxIcon'><AttachMoneyOutlinedIcon /></div>
                        <div className="infoBoxItem">{outputNumber(totalValue, 0)} USD</div>
                        <div className='infoBoxItem2'>Total portfolio value</div>
                    </div>
                    <div className='portfolioAssetBox'>
                        <div className="portfolioBoxHeader">
                            <div className="">Your Assets</div>
                        </div>
                        <div className="row mainPortfolioContent">    
                            <div className="pl-3 pr-3  w-100">
                                {/* Example render output for your assets */}
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Symbol</th>
                                            <th className="text-left">Name</th>
                                            <th className="text-right">Price</th>
                                            <th className="text-right">Position</th>
                                            <th className="text-right">Expiry Date</th>
                                            <th className="text-right">Upper limit</th>
                                            <th className="text-right">Status</th>
                                            <th className="text-right">Value (USD)</th>
                                            <th className="text-right" scope="col"></th>
                                        </tr>
                                    </thead>
                                    
                                        {portfolioAssets && portfolioAssets.length > 0 ? (
                                            <tbody>
                                                {portfolioAssets.map((asset, index) => (
                                                    <tr key={index}>
                                                        <td className="text-left">{asset}</td>
                                                        <td className="text-left">{portfolio[asset]?.name}</td>
                                                        <td className="text-right">{outputNumber(portfolio[asset]?.price, 2)}</td>
                                                        <td className="text-right">{outputNumber(portfolio[asset]?.balance, 4)}</td>
                                                        <td className="text-right">{asset !== "TWIN" && asset !== "HONEY" ? timeStampToDate(portfolio[asset]?.expiryDate) : 'n.a.'}</td>
                                                        <td className="text-right">{asset !== "TWIN" && asset !== "HONEY" ? outputNumber((parseFloat(portfolio[asset]?.upperLimit) / 1000), 2) : 'n.a.'}</td>
                                                        <td className="text-right">{portfolio[asset]?.isFrozen ? 'frozen' : <div className='portfolioStatusLive'>LIVE</div>}</td>
                                                        <td className="text-right">{outputNumber(portfolio[asset]?.portfolioValue, 0)}</td>
                                                        <td>
                                                            {portfolio[asset]['name'] !== stableCoinName ?
                                                                <div className="d-flex flex-row">
                                                                    <Link to={`/trade/buy/${portfolio[asset]['marketPair']}`}>
                                                                        <div className="buyButton">Buy</div>
                                                                    </Link>
                                                                    <Link to={`/trade/sell/${portfolio[asset]['marketPair']}`}>
                                                                        <div className="sellButton">Sell</div>
                                                                    </Link>
                                                                </div>
                                                            :''}
                                                        </td>
                                                    </tr>
                                                ))} 
                                            </tbody>  
                                            
                                        ) : (
                                            <tbody>
                                            <tr>
                                                <td colSpan="6">No assets available</td>
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                            </tr>
                                            </tbody>
                                        )}
                                        <tfoot>
                                        <tr>
                                            <td className="text-left"><b>Total Assets</b></td>
                                            <td className="text-right"></td>
                                            <td></td>                                            
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right"><b>{outputNumber(assetValue,0)}</b></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>   
                    </div>

                    <div className='portfolioAssetBox'>           
                        <div className="portfolioBoxHeader">
                            <div className="">Your liquidity provider tokens</div>
                        </div>
                        <div className="row mainPortfolioContent">    
                            <div className="pl-3 pr-3 w-100">
                                <table className="w-100">
                                    <tr className="">
                                        <th className="text-left" scope="col">Symbol</th>
                                        <th className="text-left" scope="col">Name</th>
                                        <th className="text-right" scope="col">LP-Balance</th>
                                        <th className="text-right" scope="col">Withdrawable Assets</th>                                            
                                        <th className="text-right" scope="col">Pool share</th>
                                        <th className="text-right" scope="col">Value (USD)</th>
                                    </tr>
                                    <tbody>
                                    {lpTokens.length > 0 ? (
                                        lpTokens.map((lp, index) => (
                                            <tr key={index}>
                                                <td className="text-left">{lp.symbol}</td>
                                                <td className="text-left">{lp.name}</td>
                                                <td className="text-right">{lp.lpBalance}</td>
                                                <td className="text-right">{lp.withdrawableAssets}</td>
                                                <td className="text-right">{lp.poolShare}</td>
                                                <td className="text-right">{lp.value}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No LP tokens available</td>
                                            
                                        </tr>
                                    )}
                                    <tr>
                                        <td className="text-left"><b>Total LP tokens</b></td>
                                        <td></td>
                                        <td></td>                                            
                                        <td></td>
                                        <td></td>
                                        <td className="text-right"><b>{outputNumber(LPValue, 0)}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>  
                        </div>                                    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
